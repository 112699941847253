%pl_basicSettingsList {
  box-sizing: border-box;
  font-family: $sansFontFamily;
  font-size: map-get($pl_copy100, "font-size");
  line-height: map-get($pl_copy100, "line-height");
  margin-left: 24px;

  li {
    margin-bottom: 8px;

    > ul,
    > ol {
      margin-top: 8px;

      > li + li {
        margin-top: 8px;
      }

      > li:last-child {
        margin-bottom: 16px;
      }
    }
  }

  > li:last-child {
    margin-bottom: 0px;
  }
}

.pl_list--unordered {
  @extend %pl_basicSettingsList;
  list-style-type: disc;

  li > ul {
    list-style-type: circle;
  }
}

.pl_list--ordered {
  @extend %pl_basicSettingsList;
  list-style-type: decimal;

  li > ol {
    list-style-type: lower-latin;
  }
}
