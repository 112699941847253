/*                                                         */
@use "../../common/scss/focus";

%pl_switch_base {
  height: 0;
  opacity: 0;
  outline: 0 none;
  position: absolute;
  width: 0;

  + label {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    user-select: none;
  }

  &[disabled]:hover,
  &[disabled]:active,
  &[disabled] {
    + label {
      color: $pl_color-grey400;
      cursor: default;
    }
  }
}

%pl_switch {
  @extend %pl_switch_base;

  &:focus-visible:not(:active) + label:after {
    @include focus.common-styles;
  }

  @supports not selector(*:focus-visible) {
    &:focus:not(:active) + label:after {
      @include focus.common-styles;
      /*                                                                                 */
      /*                                           */
      outline-style: auto;
    }
  }
}

%pl_switch_label-right {
  @extend %pl_switch_base;

  &:focus-visible:not(:active) + label:before {
    @include focus.common-styles;
  }

  @supports not selector(*:focus-visible) {
    &:focus:not(:active) + label:before {
      @include focus.common-styles;
      /*                                                                                 */
      /*                                           */
      outline-style: auto;
    }
  }
}

%pl_switch_background_base {
  align-self: flex-start;
  background-color: $pl_color-grey200;
  border-radius: $pl_border-radius100;
  content: "";
  transition: background-color 0.2s ease-in-out;
}

%pl_switch_background_disabled {
  background-color: $pl_color-grey100;
}

%pl_switch50_background {
  @extend %pl_switch_background_base;

  height: 24px;
  min-width: 36px;
}

%pl_switch100_background {
  @extend %pl_switch_background_base;

  height: 32px;
  min-width: 48px;
}

%pl_switch_dot_base {
  background-color: $pl_color-white100;
  border-radius: 50%;
  content: "";
  height: 24px;
  position: absolute;
  top: 4px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  width: 24px;
}

%pl_switch_dot_disabled {
  background-color: $pl_color-grey200;
}

%pl_switch50_dot {
  @extend %pl_switch_dot_base;

  height: 16px;
  width: 16px;
}

%pl_switch100_dot {
  @extend %pl_switch_dot_base;

  height: 24px;
  top: 4px;
  width: 24px;
}

%pl_switch_check_icon_base {
  background-position-x: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
}

%pl_switch50_check_icon {
  @extend %pl_switch_check_icon_base;

  background-image: url('data:image/svg+xml;utf-8,<svg width="12" height="12" viewBox="0 0 12 12" xmlns="http:/*                                                                                                                                                                                                                                                                                                                                                                                                                                                                    */
  background-position-y: 3px;
}

%pl_switch50_check_icon_disabled {
  @extend %pl_switch_check_icon_base;

  background-image: url('data:image/svg+xml;utf-8,<svg width="12" height="12" viewBox="0 0 12 12" fill="white" xmlns="http:/*                                                                                                                                                                                                                                                                                                                                                                                                                                                                    */
  background-position-y: 3px;
}

%pl_switch100_check_icon {
  @extend %pl_switch_check_icon_base;

  background-image: url('data:image/svg+xml;utf-8,<svg width="16" height="16" viewBox="0 0 12 12" xmlns="http:/*                                                                                                                                                                                                                                                                                                                                                                                                                                                                    */
  background-position-y: 5px;
}

%pl_switch100_check_icon_disabled {
  @extend %pl_switch_check_icon_base;

  background-image: url('data:image/svg+xml;utf-8,<svg width="16" height="16" viewBox="0 0 12 12" fill="white" xmlns="http:/*                                                                                                                                                                                                                                                                                                                                                                                                                                                                    */
  background-position-y: 5px;
}

.pl_switch50 {
  @extend %pl_switch;

  + label {
    &:before {
      @extend %pl_switch50_dot;

      right: 16px;
    }

    &:after {
      @extend %pl_switch50_background;
    }

    &:not(:empty) {
      &:after {
        margin-left: $pl_margin100;
      }
    }
  }

  &:checked + label {
    &:before {
      @extend %pl_switch50_check_icon;

      transform: translateX(12px);
    }

    &:after {
      background-color: $pl_color-success;
    }
  }
}

.pl_switch50--label-right {
  @extend %pl_switch_label-right;

  + label {
    &:before {
      @extend %pl_switch50_background;

      margin-right: $pl_margin100;
    }

    &:after {
      @extend %pl_switch50_dot;

      left: 4px;
    }
  }

  &:checked + label {
    &:before {
      background-color: $pl_color-success;
    }

    &:after {
      @extend %pl_switch50_check_icon;

      transform: translateX(12px);
    }
  }
}

.pl_switch100 {
  @extend %pl_switch;

  + label {
    &:before {
      @extend %pl_switch100_dot;

      right: 20px;
    }

    &:after {
      @extend %pl_switch100_background;
    }

    &:not(:empty) {
      &:after {
        margin-left: $pl_margin100;
      }
    }
  }

  &:checked + label {
    &:before {
      @extend %pl_switch100_check_icon;

      transform: translateX(16px);
    }

    &:after {
      background-color: $pl_color-success;
    }
  }
}

.pl_switch100--label-right {
  @extend %pl_switch_label-right;

  + label {
    &:before {
      @extend %pl_switch100_background;

      margin-right: $pl_margin100;
    }

    &:after {
      @extend %pl_switch100_dot;

      left: 4px;
    }
  }

  &:checked + label {
    &:before {
      background-color: $pl_color-success;
    }

    &:after {
      @extend %pl_switch100_check_icon;

      transform: translateX(16px);
    }
  }
}

/*              */

.pl_switch50,
.pl_switch100 {
  &:checked:disabled + label,
  &[disabled] + label {
    &:before {
      @extend %pl_switch_dot_disabled;
    }
  }

  &:checked:disabled + label,
  &[disabled] + label {
    &:after {
      @extend %pl_switch_background_disabled;
    }
  }
}

.pl_switch50--label-right,
.pl_switch100--label-right {
  &:checked:disabled + label,
  &[disabled] + label {
    &:before {
      @extend %pl_switch_background_disabled;
    }
  }

  &:checked:disabled + label,
  &[disabled] + label {
    &:after {
      @extend %pl_switch_dot_disabled;
    }
  }
}

.pl_switch50 {
  &:checked:disabled + label {
    &:before {
      @extend %pl_switch50_check_icon_disabled;
    }
  }
}

.pl_switch100 {
  &:checked:disabled + label {
    &:before {
      @extend %pl_switch100_check_icon_disabled;
    }
  }
}

.pl_switch50--label-right {
  &:checked:disabled + label {
    &:after {
      @extend %pl_switch50_check_icon_disabled;
    }
  }
}

.pl_switch100--label-right {
  &:checked:disabled + label {
    &:after {
      @extend %pl_switch100_check_icon_disabled;
    }
  }
}
