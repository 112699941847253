@mixin pl_selectiontile-rounded-background($name, $background) {
  &.#{$name} {
    input[type="radio"] + label,
    input[type="checkbox"] + label {
      background: $background;
    }
  }
}

.pl_selectiontile-rounded100 {
  @include pl_selectiontile-rounded-background("weiss", #ffffff);
  @include pl_selectiontile-rounded-background("schwarz", #222222);
  @include pl_selectiontile-rounded-background("braun", #7e3906);
  @include pl_selectiontile-rounded-background("natur", #f0ede1);
  @include pl_selectiontile-rounded-background("beige", #f0ede1);
  @include pl_selectiontile-rounded-background("gelb", #ffd229);
  @include pl_selectiontile-rounded-background("orange", #ee9201);
  @include pl_selectiontile-rounded-background("rot", #d52b1e);
  @include pl_selectiontile-rounded-background("rosa", #ff889e);
  @include pl_selectiontile-rounded-background("lila", #8d0083);
  @include pl_selectiontile-rounded-background("blau", #334982);
  @include pl_selectiontile-rounded-background("gruen", #198504);
  @include pl_selectiontile-rounded-background("grau", #a6a9a7);
  @include pl_selectiontile-rounded-background(
    "goldfarben",
    linear-gradient(to right top, #aa5b00 0, #994700 17%, #ffe284 54%, #ffe284 70%, #994700 95%)
  );
  @include pl_selectiontile-rounded-background(
    "silberfarben",
    linear-gradient(to right top, #888a87 17%, #e9ebe7 54%, #e9ebe7 70%, #888a87 95%)
  );
  @include pl_selectiontile-rounded-background(
    "bunt",
    linear-gradient(
      to right top,
      #7e3906 0,
      #7e3906 10%,
      #ff889e 10%,
      #ff889e 23%,
      #8d0083 23%,
      #8d0083 37%,
      #198504 37%,
      #198504 48.5%,
      #334982 48.5%,
      #334982 63%,
      #ffd229 63%,
      #ffd229 76%,
      #ee9201 76%,
      #ee9201 90%,
      #d52b1e 90%,
      #d52b1e 100%
    )
  );
}
