@use "~@otto-ec/global-frontend-settings/all" as gfs;

%headerIcons {
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  width: 24px;
  -webkit-tap-highlight-color: transparent;
}

%iconButtonHoverState {
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
}

.prevent-scroll-y {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.pl_sheet-curtain {
  background-color: rgba(34, 34, 34, 0.4);
  box-sizing: border-box;
  height: 100%;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  touch-action: none; /*                                                         */
  transition: opacity ease 0.3s;
  width: 100%;
  z-index: 9001;

  &--hidden {
    opacity: 0;
    transition: opacity ease 0.3s;
  }
}

.pl_sheet {
  --sheet-max-height: 80vh;

  background-color: gfs.$pl_color-white100;
  border-radius: gfs.$pl_border-radius100 gfs.$pl_border-radius100 0 0;
  bottom: 0;
  left: 0;
  max-height: 80vh; /*                                                                     */
  max-height: var(--sheet-max-height);
  opacity: 1;
  position: fixed;
  transform: translateY(100%);
  width: 100vw;
  z-index: 9001;

  &--app {
    --sheet-max-height: 90vh;
  }

  &--open {
    transform: translateY(0);
    transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1); /*                                  */
  }

  &--close {
    transition: transform 0.2s cubic-bezier(0.32, 0, 0.67, 0); /*                                 */
  }

  &--grey-bg {
    background-color: gfs.$pl_color-grey25;
  }

  &__menu-bar-hidden, /*                           */
  &--menu-bar-hidden {
    .pl_sheet__menu-bar {
      display: none;
    }
  }

  &__header {
    align-items: flex-start;
    display: flex;
    min-height: 64px;
    padding: gfs.$pl_padding150 gfs.$pl_padding100 gfs.$pl_padding100;
    touch-action: none;
    background-color: gfs.$pl_color-white100;
    border-radius: gfs.$pl_border-radius100 gfs.$pl_border-radius100 0 0;
  }

  &__title {
    flex: 1 0 auto;
    max-height: 48px;
    max-width: calc(100% - 24px);
    overflow: hidden;
  }

  /*                              */
  &__back-button,
  &__close-button {
    background: none;
    border: 0;
    margin: 0;
    outline: 0 none;
    padding: 0;
  }

  /*                                                             */
  /*                                             */
  @at-root button#{&}__back-button {
    align-items: center;
    display: flex;
    margin-right: gfs.$pl_margin100;

    > i {
      @extend %headerIcons;
      background-image: url("/assets-static/icons/pl_icon_arrow-left.svg#pl_icon_arrow-left");
    }

    @extend %iconButtonHoverState;
  }

  /*                                                             */
  /*                                              */
  @at-root button#{&}__close-button {
    > i {
      @extend %headerIcons;
      background-image: url("/assets-static/icons/pl_icon_close.svg#pl_icon_close");
    }

    @extend %iconButtonHoverState;
  }

  &__content {
    height: 100%;
    overflow: auto;
    padding: 0 gfs.$pl_padding100 gfs.$pl_padding100 gfs.$pl_padding100;
    position: relative;

    > .spacer {
      height: 16px;
    }

    &--full-bleed {
      padding: 0;
    }

    /*                          */
    &-error {
      margin: 40px 20px;
    }
  }

  &__menu-bar {
    background-color: gfs.$pl_color-white100;
    display: flex;
    min-height: 96px;
    padding: gfs.$pl_padding100 gfs.$pl_padding100 gfs.$pl_padding175 gfs.$pl_padding100;

    button {
      flex: 1 0 10%;
      -webkit-tap-highlight-color: transparent;

      + button {
        margin-left: gfs.$pl_margin50;
      }
    }

    &--vertical {
      flex-direction: column;

      button + button {
        margin-left: 0;
        margin-top: gfs.$pl_margin50;
      }
    }
  }

  @media only screen and (min-width: 480px) {
    & {
      border-radius: gfs.$pl_border-radius100 0 0 gfs.$pl_border-radius100;
      bottom: 0;
      height: 100vh;
      margin-left: auto;
      max-height: 100vh;
      right: 0;
      transform: translateX(100%);
      width: 448px;

      &--open {
        transform: translateX(0);
      }

      &__header {
        border-radius: gfs.$pl_border-radius100 0 0 0;
      }

      &__content {
        height: unset;
      }

      &__menu-bar {
        background-color: unset;
      }
    }
  }
}
