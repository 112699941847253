@use "~@otto-ec/global-frontend-settings/gfs";

.p_table {
  box-sizing: border-box;
  @include font-size($font100, true);
  border-collapse: collapse;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.p_table--separator {
    tr {
      border-bottom: 1px solid $grey200;
    }
  }
}

.p_table--separator:not(.p_table) {
  border-bottom: 1px solid $grey200;
}

.p_table--zebraStriped {
  .p_table__row:nth-child(even) {
    background: $grey25;
  }
}

.p_table__cell {
  border-right: 1px solid $grey200;
  padding: 8px;
  text-align: left;

  &:last-child {
    border: none;
  }
}

.p_table__head {
  background: $grey100;

  .p_table__cell {
    font-weight: bold;
  }
}

.p_table__cell--subheadline {
  font-weight: bold;
}

/*                                                     */
/*                            */
@media (max-width: 47.99em) {
  .p_table:not(.p_table--nonResponsive) {
    display: block;
    width: 100%;

    .p_table__body,
    .p_table__row {
      display: block;
      width: 100%;
    }

    .p_table__head {
      display: none;
    }

    .p_table__cell {
      border: none;
      display: block;
      float: left;
      padding-left: 50%;
      width: 100%;
    }

    .p_table__cell:before {
      content: attr(data-title);
      display: block;
      font-weight: bold;
      left: 0;
      padding: 0 8px;
      position: absolute;
      width: 50%;
    }

    /*                                                    */
    .p_table__cell.p_table__cell--subheadline {
      background: $grey100;
      margin-top: 8px;
    }

    .p_table__cell.p_table__cell--subheadline:first-child {
      margin-top: 0;
    }

    /*                         */
    &.p_table--zebraStriped .p_table__row:nth-child(even) {
      background: none;
    }
  }
}
