/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

/*             */
.p_tooltip {
  box-sizing: border-box;
  @include font-size($font100, true);
  animation: fadeIn 0.5s;
  background: $white;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  box-shadow: 0 0 9px rgba(34, 34, 34, 0.3);
  max-width: 200px;
  padding: 10px;
  position: absolute;
  text-align: left;
  z-index: 9999;

  &.p_tooltipHorizontal:after {
    border-color: $white transparent;
  }

  &--error {
    background-color: $error-background-color;
    color: $error-font-color;

    &.p_tooltipHorizontal:after {
      border-color: $error-background-color transparent;
    }
  }

  &--warning {
    background-color: $warning-background-color;
    color: $warning-font-color;

    &.p_tooltipHorizontal:after {
      border-color: $warning-background-color transparent;
    }
  }

  &--hint {
    background-color: $hint-background-color;
    color: $hint-font-color;

    &.p_tooltipHorizontal:after {
      border-color: $hint-background-color transparent;
    }
  }

  &--success {
    background-color: $success-background-color;
    color: $success-font-color;

    &.p_tooltipHorizontal:after {
      border-color: $success-background-color transparent;
    }
  }
}

.p_tooltip:before,
.p_tooltip:after {
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  z-index: 1;
}

.p_tooltip.p_tooltipHorizontal:before,
.p_tooltip.p_tooltipHorizontal:after {
  border-width: 8px 7px 0;
  left: 12px;
}

.p_tooltip.p_tooltipHorizontal.p_tooltipHorizontalTop:before,
.p_tooltip.p_tooltipHorizontal.p_tooltipHorizontalTop:after {
  border-width: 0 7px 8px;
  left: 12px;
}

.p_tooltip.p_tooltipHorizontal:before {
  border-color: #c2c2c2 transparent;
  bottom: -8px;
}

.p_tooltip.p_tooltipHorizontal:after {
  bottom: -7px;
}

.p_tooltip.p_tooltipHorizontal.p_tooltipHorizontalTop:before {
  bottom: auto;
  top: -8px;
}

.p_tooltip.p_tooltipHorizontal.p_tooltipHorizontalTop:after {
  bottom: auto;
  top: -7px;
}

.p_tooltip.p_tooltipHorizontal.p_tooltipHorizontalRight:before,
.p_tooltip.p_tooltipHorizontal.p_tooltipHorizontalRight:after {
  left: auto;
  right: 12px;
}

.p_tooltip span {
  color: #999999;
}

.p_tooltip .p_tooltip__close {
  background: url("/assets-static/icons/pl_icon_close.svg#pl_icon_close");
  width: 18px;
  height: 18px;
  position: absolute;
  right: 8px;
  top: 8px;

  &:hover {
    cursor: pointer;
  }
}

.p_tooltip__content {
  overflow: hidden;
}

.p_tooltip__close + .p_tooltip__content {
  margin-right: 30px;
}
