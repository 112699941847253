.pl_counter {
  color: $pl_color-grey400;
  display: inline-block;
  flex: 0 1 auto;
  font-size: map-get($pl_copy75, "font-size");
  line-height: map-get($pl_copy75, "line-height");
  margin-left: auto;
  margin-top: 4px;
}

*[class$="--invalid"] {
  > .pl_counter {
    color: $pl_color-red100;
  }
}

*[class$="--success"] {
  > .pl_counter {
    color: $pl_color-success;
  }
}

*:disabled ~ .pl_counter {
  color: $pl_color-grey200;
}
