@use "~@otto-ec/global-frontend-settings/gfs";

/*             */
/*                            */
/*            */
$layerCloseSize: 40px;

/*              */
/*            */
$layerTopPaddingS: 16px;

/*            */
$layerRightPaddingS: 8px;

/*            */
$layerBottomPaddingS: 24px;

/*            */
$layerLeftPaddingS: 8px;

/*                                                                                                        */
/*            */
$layerRightPaddingM: 16px;

/*            */
$layerLeftPaddingM: 16px;

/*                                          */
/*            */
$layerInnerPaddingLXL: 20px;

/*            */
.p_curtain {
  box-sizing: border-box;
  @include font-size($font100, true);
  transition: opacity ease 0.4s;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9001;
}

.p_curtain--hidden {
  opacity: 0;
}

.p_layer {
  box-sizing: border-box;
  @include font-size($font100, true);
  transform: translateY(0);

  /*                                                                                                          */
  opacity: 1;
  left: 0;
  position: absolute;
  right: 0;
  transition: transform 0.4s ease;
  z-index: 9001;

  .app & {
    /*                                                                                                                            */
    /*                                                                                                                        */
    transform: translateY(0) translateZ(0);
  }

  /*                                                                                   */
  &.p_layer--forcedWidth {
    /*                         */
    @media (min-width: 0) and (max-width: 47.99em) {
      width: auto !important;
    }
  }

  /*                          */
  @include gfs.from-m("l") {
    right: $layerCloseSize;
  }

  /*                                                  */
  @include gfs.from-l() {
    transition: opacity 0.4s ease;

    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    width: 83.3333333333%;
  }

  /*                          */
  @include gfs.from-xl {
    max-width: 900px;
    width: 66.6666666667%;
  }

  /*                                         */
  &:after {
    position: absolute;
    content: "";
    bottom: -60px;
    height: 60px;
    width: 1px;
  }
}

.p_layer--hidden {
  @include gfs.from-l() {
    transition: opacity 0.4s ease;

    opacity: 0;
  }
}

/*                                                   */
.p_layer--headerRemoved,
.p_layer--headerRemovedOnSmallScreens {
  /*                                  */
  right: 0;

  /*                              */
  .p_layer__wrapper {
    top: 0;
  }
}

.p_layer--headerRemovedOnSmallScreens {
  /*                 */
  .p_layer__header {
    display: none;
  }
  .p_layer_screenreader__close {
    display: block;
  }

  /*                          */
  @include gfs.from-l() {
    .p_layer__header {
      display: block;
    }

    .p_layer_screenreader__close {
      display: none;
    }
  }
}

/*           */
.p_layer__content {
  .p_loader200 {
    margin: 50px auto;
    position: relative;
  }
}

/*                          */
.p_layer--error {
  margin: 40px 20px;
}

.p_layer__header {
  height: $layerCloseSize;

  &:after {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    clip: rect(5px, 10px, 10px, 5px); /*                          */
    content: "";
    height: 5px;
    position: absolute;
    right: 0;
    top: 35px;
    width: 5px;
  }

  /*                                           */
  @include gfs.from-m("l") {
    height: auto;

    &:after {
      clip: rect(-5px, 0, 0, -5px); /*                          */
      right: -5px;
      top: 0;
    }
  }
}

.p_layer__close {
  background-color: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  clip: rect(-5px, 45px, 41px, -5px); /*                          */
  cursor: pointer;
  display: block;
  font-family: $iconsFontFamily;
  @include font-size($font175, false);
  height: $layerCloseSize + 1px;
  line-height: $layerCloseSize;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  width: $layerCloseSize;
  z-index: 1;

  @include gfs.from-m("l") {
    clip: rect(-5px, 45px, 45px, 0); /*                          */
    right: -$layerCloseSize; /*                                      */
  }

  @include gfs.from-l() {
    clip: rect(-5px, 45px, 41px, -5px); /*                          */
    right: 0;
  }
}

.p_layer_screenreader__close {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.p_layer__headline {
  @include font-size($font150);
  margin: $layerTopPaddingS $layerRightPaddingS $layerTopPaddingS $layerLeftPaddingS;

  @include gfs.from-m() {
    margin: $layerTopPaddingS $layerRightPaddingM $layerTopPaddingS $layerLeftPaddingM;
  }

  @include gfs.from-l() {
    @include font-size($font200);
    margin: $layerInnerPaddingLXL;
  }
}

.p_layer__wrapper {
  background-color: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  position: relative;
}

.p_layer__body {
  margin: $layerTopPaddingS $layerRightPaddingS $layerBottomPaddingS $layerLeftPaddingS;

  @include gfs.from-m() {
    margin: $layerTopPaddingS $layerRightPaddingM $layerBottomPaddingS $layerLeftPaddingM;
  }

  @include gfs.from-l() {
    margin: 20px $layerInnerPaddingLXL $layerInnerPaddingLXL $layerInnerPaddingLXL;
  }
}

/*                                                             */
.p_layer__headline + .p_layer__body {
  margin-top: 0;
}

.p_layer__content {
  padding-bottom: 20px;
  overflow: hidden;
}

.p_layer__menu {
  display: inline-block;
  padding: $layerTopPaddingS $layerRightPaddingS 0 $layerLeftPaddingS;
  width: 100%;
  vertical-align: top;

  /*                                               */
  @include gfs.from-l() {
    bottom: 0;
    padding: 0 $layerInnerPaddingLXL $layerInnerPaddingLXL;
    position: absolute;
    top: auto;
    z-index: 1;
  }
}

.p_layer__menu + .p_layer__content {
  /*                                               */
  @include gfs.from-l() {
    padding-bottom: 80px;
  }
}

.p_layer__button {
  float: left;
  margin-left: 2px;
  width: 139px;

  &:first-child {
    margin-left: 0;
  }
}

.p_layer__body {
  .p_formGroup__row .p_form__input[type="radio"],
  .p_formGroup__row .p_form__input[type="checkbox"] {
    position: absolute;
  }
}
