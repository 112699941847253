.p_animation {
  &__initialHidden {
    opacity: 0;
  }

  &__fadeOut {
    transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s; /*                                      */
    opacity: 0;
  }

  &__fadeIn {
    transition: opacity cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s; /*                                      */
    opacity: 1;
  }
}
