.pl_icon {
  fill: $pl_color-black100;
}

.pl_icon50 {
  @extend .pl_icon;
  width: 12px;
  height: 12px;
}

.pl_icon100 {
  @extend .pl_icon;
  width: 24px;
  height: 24px;
}
