@mixin labelFloated {
  color: $pl_color-grey400;
  transform: translate(11px, -8px) scale(0.75);
  transition:
    transform 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    max-width 0.21s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: default;
  max-width: calc((100% - 16px - 6px) / 0.75);
  box-sizing: border-box;
  border-radius: 8px;

  .p_input--invalid &,
  .pl_input--invalid &,
  .p_dropdown--invalid &,
  .pl_dropdown--invalid &,
  .p_textarea--invalid &,
  .pl_textarea--invalid & {
    color: $pl_color-red100;
  }

  .p_textarea--success &,
  .pl_textarea--success & {
    color: $pl_color-success;
  }

  .p_input--valid & {
    color: $pl_color-success;
  }

  .pl_input--success & {
    color: $pl_color-success;
  }
}

.p_label,
.pl_label {
  padding: 0 8px;
  background-color: #fff;
  position: absolute;
  user-select: none;
  overflow: hidden;
  color: $pl_color-grey400;
  display: inline-block;
  margin: 0;
  font-size: map-get($pl_copy125, "font-size");
  line-height: map-get($pl_copy125, "line-height");
  /*                                                                      */
  transform: translate(8px, 12px) scale(1);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 0 0;
  max-width: calc(100% - 16px);
  top: 0;
  left: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: text;
  border-radius: 8px;
  width: auto;

  .p_dropdown &,
  .pl_dropdown & {
    transition: none;
  }

  .p_input--invalid &,
  .pl_input--invalid &,
  .p_dropdown--invalid &,
  .pl_dropdown--invalid &,
  .p_textarea--invalid &,
  .pl_textarea--invalid & {
    color: $pl_color-red100;
  }

  .p_textarea--success &,
  .pl_textarea--success & {
    color: $pl_color-success;
  }

  .p_input--hint & {
    color: $hint-inverted-color;
  }

  .p_input--valid & {
    color: $pl_color-success;
  }

  .pl_input--success & {
    color: $pl_color-success;
  }

  .pl_textarea__element & {
    @media (hover: hover) {
      &:hover {
        cursor: text;
      }
    }
  }
}

.p_input > label,
.pl_input > label,
.p_textarea__element > label,
.pl_textarea__element > label {
  @media (hover: hover) {
    &:hover {
      cursor: text;
    }
  }
}

.p_textarea__element,
.pl_textarea__element {
  > .p_label,
  > .pl_label {
    cursor: text;
  }
}

.js-p_label,
.js-pl_label {
  /*                                                            */
  transition: none !important;
}

/*                                                                                   */
.p_input__field:focus,
.p_input__field:not([value=""]),
.pl_input__field:focus,
.pl_input__field:not([value=""]),
.p_textarea__element:focus,
.pl_textarea__element:focus,
.p_dropdown__element[data-value]:not([data-value=""]),
.pl_dropdown__element[data-value]:not([data-value=""]) {
  + .p_label,
  + .pl_label {
    @include labelFloated;
  }
}

.p_input__field:not(:placeholder-shown),
.pl_input__field:not(:placeholder-shown),
.p_textarea__element:not(:placeholder-shown),
.pl_textarea__element:not(:placeholder-shown) {
  + .p_label,
  + .pl_label {
    @include labelFloated;
  }
}

.p_input--floating-label,
.pl_input--floating-label {
  input.p_input__field,
  input.pl_input__field {
    /*                                                                  */
    & + label {
      @include labelFloated;
    }
  }
}

.p_input__field:autofill,
.pl_input__field:autofill {
  + .p_label,
  + .pl_label {
    @include labelFloated;
  }
}
