.pl_logo {
  &--red {
    fill: $pl_color-red100;
  }

  &--grey {
    fill: $pl_color-grey200;
  }
}

.label {
  color: $pl_color-grey400;
}

.logo-sizing {
  > svg,
  > img {
    height: 32px;
  }

  #pl_logo_ehi {
    width: 40px;
  }

  #pl_logo_up,
  #pl_logo_otto-payments-logo {
    width: 55px;
  }

  #pl_logo_otto {
    width: 70px;
  }

  #pl_logo_sepa {
    width: 85px;
  }

  #pl_logo_paypal,
  #pl_logo_two-for-fashion,
  #pl_logo_app-store,
  #pl_logo_play-store {
    width: 100px;
  }

  #pl_logo_otto-up,
  #pl_logo_slogan,
  #pl_logo_soulfully {
    width: 120px;
  }

  #pl_logo_roombeez {
    width: 140px;
  }

  &.social {
    > svg {
      width: 50px;
    }
  }
}
