@use "sass:math";

/**/
/*             */
/*             */
/**/

///
/*                                                                          */
///
/*                                                                  */
/*                                                         */
///
/*                                                     */
/*                                                                               */
///
@mixin font-size($font, $line-height: true) {
  $fontSize: (map-get($font, font-size));
  @if unit($fontSize) == rem {
    font-size: $fontSize;
  } @else if unit($fontSize) == px {
    /*                                             */
    font-size: (math.div($fontSize, 16px) + rem);
  } @else {
    @error "Inalid font-size unit: '#{$fontSize}'.";
  }
  $lineHeight: map-get($font, line-height);
  @if $line-height == true and $lineHeight != "" {
    @if unitless($lineHeight) {
      line-height: $lineHeight;
    } @else if unit($lineHeight) == px {
      /*                                             */
      line-height: math.div($lineHeight, $fontSize) + em;
    } @else {
      @error "Invalid line-height unit: '#{$lineHeight}'.";
    }
  }
}
