@use "~@otto-ec/global-frontend-settings/gfs";

.p_form {
  box-sizing: border-box;
  @include font-size($font100, true);
  overflow: hidden;

  @include gfs.from-m() {
    overflow: visible;
  }

  .p_link100--1st {
    display: block;
    padding: 10px 0;
  }
}

.p_form__headline {
  @include font-size($font125);
  font-family: $sansFontFamily;
  padding: 9px 0;
}

.p_form__input-clear-wrapper {
  position: relative;

  .p_form__input {
    padding-right: 34px;
    width: 100%;

    &::-ms-clear {
      display: none;
    }
  }
}

.p_form__input-clear-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: 0 none;
  padding: 13px 12px 11px 12px;
  margin: 0;
  font-size: 1em;
  color: $grey300;

  /*                                                           */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &--hide {
    display: none;
  }
}

.p_form__input,
.p_form__select,
.p_form__textarea,
.p_form__message {
  /*                                                                         */
  box-sizing: border-box;
  @include font-size($font100, true);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  background-color: $white;
  border: 1px solid $grey300;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: $black100;
  display: block;
  height: 40px;
  margin: 0;
  outline: none;
  padding: 8px 9px;
  vertical-align: baseline;
  width: 100%;
}

.p_form__input,
.p_form__select,
.p_form__textarea {
  @include font-size($font150, true);
  @include gfs.from-l() {
    @include font-size($font100, true);
  }
}

.p_form__select {
  cursor: pointer;
  padding: 0 0 0 4px;
}

/**
 */
.p_form__select--androidworkaround {
  height: 40px;
  width: 100%;
}

.p_form__select--androidworkaround--error {
  color: $error-inverted-color;
}

.p_form__option {
  background-color: $white;
  color: $black100;
}

.p_form__option[disabled] {
  color: $disabled-color;
}

.p_form__textarea {
  height: 148px;
}

.p_form__input[type="radio"],
.p_form__input[type="checkbox"] {
  box-shadow: 0 1px 0 $grey200 inset, 0 -1px 0 $grey200 inset, -1px 0 0 $grey200 inset,
    1px 0 0 $grey200 inset;
  -webkit-appearance: none;
  border: none;
  display: inline-block;
  float: none;
  height: 20px;
  margin-top: 10px;
  padding: 0;
  position: relative;
  vertical-align: top;
  width: 20px;
}

.p_form__input--error[type="radio"],
.p_formGroup__row--error .p_form__input[type="radio"],
.p_form__input--error[type="checkbox"],
.p_formGroup__row--error .p_form__input[type="checkbox"] {
  box-shadow: 0 1px 0 $error-background-color inset, 0 -1px 0 $error-background-color inset,
    -1px 0 0 $error-background-color inset, 1px 0 0 $error-background-color inset;
}

.p_form__input[type="radio"]:checked:after {
  background: $availability-green;
  border-radius: 50%;
  content: "\00a0";
  display: block;
  height: 10px;
  left: 5px;
  position: relative;
  top: 5px;
  width: 10px;
}

.p_form__input[type="radio"] {
  border-radius: 50%;
}

.p_form__input[type="checkbox"]:checked:after {
  border-radius: 0;
  color: $availability-green;
  content: "\221a";
  display: block;
  font-family: $iconsFontFamily;
  font-size: 14px;
  height: 20px;
  left: 0;
  line-height: 20px;
  position: relative;
  text-align: center;
  top: 0;
  width: 20px;
}

.p_form__input[type="checkbox"] {
  border-radius: 0;
}

.p_form__input[type="radio"] + .p_form__label,
.p_form__input[type="checkbox"] + .p_form__label {
  display: inline-block;
  max-width: calc(
    100% - 25px
  ); /*                                                                                           */
  padding: 10px 0;
}

.p_form__label--disabled {
  color: $grey200;
  cursor: not-allowed;
}

.p_form__input[disabled],
.p_form__select[disabled],
.p_form__textarea[disabled] {
  background-color: $grey25;
  color: $grey200;
  cursor: not-allowed;
}

.p_form__label--error,
.p_formGroup__row--error .p_form__label {
  color: $error-inverted-color;
}

.p_form__input--warning,
.p_formGroup__row--warning .p_form__input,
.p_form__select--warning,
.p_formGroup__row--warning .p_form__select,
.p_form__textarea--warning,
.p_formGroup__row--warning .p_form__textarea {
  background-color: $warning-form-color;
  border-color: $warning-background-color;
}

.p_form__input--error,
.p_formGroup__row--error .p_form__input,
.p_form__select--error,
.p_formGroup__row--error .p_form__select,
.p_form__textarea--error,
.p_formGroup__row--error .p_form__textarea {
  background-color: $error-form-color;
  border-color: $error-background-color;
  color: $error-inverted-color;
}

/*       */
.p_form__message {
  box-shadow: 0 0 9px rgba($black100, 0.2);
  height: auto;
  min-height: 40px;
  position: relative;
  z-index: 1;
}

.p_form__message--error {
  background-color: $error-background-color;
  border-color: $error-background-color;
  color: $error-font-color;
}

.p_form__message--hint {
  background-color: $hint-background-color;
  border-color: $hint-background-color;
  color: $hint-font-color;
}

.p_form__message--warning {
  background-color: $warning-background-color;
  border-color: $warning-background-color;
  color: $warning-font-color;
}

.p_form__message--success {
  background-color: $success-background-color;
  border-color: $success-background-color;
  color: $success-font-color;
}

/*                               */
.p_form__message:after,
.p_form__message:before {
  border: solid transparent;
  content: " ";
  position: absolute;
}

///
/*                               */
///
/*                                                                                */
/*                                                                          */
///
@mixin form-message-after($modifier-name, $background-color) {
  .p_form__message#{$modifier-name}:after {
    border-bottom-color: $background-color;

    @include gfs.from-m() {
      border-right-color: $background-color;
    }
  }
}

@include form-message-after("", $white);
@include form-message-after(--error, $error-background-color);
@include form-message-after(--hint, $hint-background-color);
@include form-message-after(--warning, $warning-background-color);
@include form-message-after(--success, $success-background-color);

.p_form__message:after {
  border-width: 9px;
  left: 19px;
  margin: 0 0 0 -8px;
  top: -16px;

  /*                                */
  @include gfs.from-m() {
    border-bottom-color: transparent;
    left: -16px;
    margin: -8px 0 0;
    top: 19px;
  }
}

///
/*                                */
///
/*                                                                                */
/*                                                                          */
///
@mixin form-message-before($modifier-name, $border-color) {
  .p_form__message#{$modifier-name}:before {
    border-bottom-color: $border-color;

    @include gfs.from-m() {
      border-right-color: $border-color;
    }
  }
}

@include form-message-before("", $grey300);
@include form-message-before(--error, $error-background-color);
@include form-message-before(--hint, $hint-background-color);
@include form-message-before(--warning, $warning-background-color);
@include form-message-before(--success, $success-background-color);

.p_form__message:before {
  border-width: 8px;
  left: 20px;
  margin: 0 0 0 -8px;
  top: -16px;

  /*                                */
  @include gfs.from-m() {
    border-bottom-color: transparent;
    left: -16px;
    margin: -8px 0 0;
    top: 20px;
  }
}

/*              */

/*               */
$formGutterWidth: 20px;

/*               */
$formFieldGutterWidth: 15px;

/*             */
.p_formFieldGroup {
  margin-right: -$formFieldGutterWidth;
  overflow: hidden;
}

.p_formFieldGroup__field {
  float: left;
  padding-right: $formFieldGutterWidth;
}

.p_formFieldGroup__field--3Of5 {
  width: 60%;
}

.p_formFieldGroup__field--1Of2 {
  width: 50%;
}

.p_formFieldGroup__field--2Of5 {
  width: 40%;
}

.p_formFieldGroup__field--1Of3 {
  width: 33.3333333333%;
}

.p_formFieldGroup__field--1Of4 {
  width: 25%;
}

.p_formFieldGroup__field--3Of4 {
  width: 75%;
}

/*        */
.p_formGroup__row {
  position: relative;

  /*                                                   */
  .p_form__input[type="radio"],
  .p_form__input[type="checkbox"] {
    position: absolute;
  }

  .p_form__input[type="radio"] + .p_form__label,
  .p_form__input[type="checkbox"] + .p_form__label {
    max-width: 100%;
    padding-left: 25px;
  }
}

.p_formGroup:first-child {
  margin-top: 0;
}

.p_formGroup__row,
.p_formGroup {
  margin-top: 30px;
}

/*                                          */
.p_formGroup--compact > .p_formGroup__row {
  margin-top: 10px;
}

.p_formGroup .p_formGroup__row:first-child {
  margin-top: 0;
}

.p_formGroup__label {
  font-weight: bold;
  margin-bottom: 5px;
}

/*               */
.p_formGroup__message {
  margin-top: 15px;
}

/*                                    */
@include gfs.from-m() {
  /*                                                                 */
  .p_formGroup__row:after {
    clear: both;
    content: " ";
    display: table;
  }

  /*                                       */
  .p_formGroup__label,
  .p_formGroup__content,
  .p_formGroup__message {
    width: 50%;
  }

  /*                    */
  .p_form--noMessages {
    .p_formGroup__label,
    .p_formGroup__content {
      width: 100%;
    }
  }

  .p_formGroup__content {
    float: left;
  }

  /*                                 */
  .p_formGroup__message {
    float: left;
    margin-top: 0;
    max-height: 40px;
    padding-left: $formGutterWidth;
  }
}

/*                                             */
@include gfs.from-l {
  /*                                       */
  .p_formGroup__label,
  .p_formGroup__content,
  .p_formGroup__message {
    width: 33.3333333333%;
  }

  /*                    */
  .p_form--noMessages {
    .p_formGroup__label,
    .p_formGroup__content {
      width: 50%;
    }

    /*                 */
    .p_formGroup__content--unlabeled {
      margin-left: 50%;
    }
  }

  /*                                            */
  .p_formGroup__label {
    font-weight: normal;
    padding: 10px $formGutterWidth 10px 10px;
    text-align: right;
  }

  .p_formGroup__label {
    float: left;
  }

  /*                                        */
  .p_formGroup__content.p_form__text,
  .p_formGroup__content > .p_form__text {
    padding: 10px 0;
  }

  /*                 */
  .p_formGroup__content--unlabeled {
    margin-left: 33.3333333333%;
  }
}
