@use "sass:color";
@use "~@otto-ec/global-frontend-settings/all" as frontend-settings;

@mixin common-styles {
  outline: 2px solid color.change(frontend-settings.$pl_color-blue100, $alpha: 0.5);
  outline-offset: 2px;
}

@mixin styles() {
  &:focus-visible:not(:active) {
    @include common-styles();
    @content;
  }

  @supports not selector(*:focus-visible) {
    &:focus:not(active) {
      @include common-styles();
      @content;
    }
  }
}
