/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

@mixin darken-arrow-down {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                                     */
  }
}

@mixin darken-arrow-up {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                                          */
  }
}

.pl_text-expander {
  @extend %p_basisSettingsBoxSizingOnly;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 140px;
  min-height: 140px;

  &.pl_text-expander--check-has-more {
    overflow: auto;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
    }
    @-moz-document url-prefix() {
      /*                                 */
      scrollbar-width: none;
    }
  }

  &__toggle {
    background: linear-gradient(
      0deg,
      rgba($pl_color-white100, 1) 40px,
      rgba($pl_color-white100, 0) 100%
    );
    min-height: 140px; /*                                              */
    bottom: 0;
    position: absolute;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__link {
    position: absolute;
    bottom: 0;
    width: 100%;

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                         */
      content: "Mehr anzeigen";
      background-size: 24px 24px;
      background-repeat: no-repeat;
      padding-left: 40px; /*                     */
      display: inline-flex;
      gap: 4px;
    }

    @media (hover: hover) {
      &:hover {
        @include darken-arrow-down;
      }
    }

    &:active {
      @include darken-arrow-down;
    }
  }

  &--expanded.pl_text-expander {
    height: auto;
    padding-bottom: 40px;

    .pl_text-expander__toggle {
      background: $pl_color-white100;
      min-height: 40px;
    }
  }
  &--has-more {
    padding-bottom: 40px; /*                                                                     */
  }
}

.pl_text-expander--expanded.pl_text-expander {
  .pl_text-expander__link {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                              */
      content: "Weniger anzeigen";
      display: block;
    }

    @media (hover: hover) {
      &:hover {
        @include darken-arrow-up;
      }
    }

    &:active {
      @include darken-arrow-up;
    }
  }
}
