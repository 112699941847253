/*          */
$variants: (
  "petrol": (
    background: $pl_color-sustainable,
    color: $white,
  ),
  "black": (
    background: $black,
    color: $white,
  ),
  "red": (
    background: $red100,
    color: $white,
  ),
);
%p_tag {
  @extend %p_basisSettingsBoxSizingOnly;
  display: inline-block;
  text-align: center;
  vertical-align: bottom;
  font-weight: bold;
  border-radius: 2px;
}
.p_tag50 {
  @extend %p_tag;
  padding: 4px 5px;
  @include font-size(
    (
      font-size: 12px,
      line-height: 12px,
    ),
    true
  );
}
.p_tag100 {
  @extend %p_tag;
  padding: 5px 6px;
  @include font-size(
    (
      font-size: 14px,
      line-height: 14px,
    ),
    true
  );
}

@each $label, $map in $variants {
  .p_tag--#{$label} {
    background-color: map-get($map, background);
    color: map-get($map, color);
  }
}
