/*                                                                    */
%pl_input {
  padding: 12px 16px;
  color: $pl_color-grey400;
  display: block;
  font-size: map-get($pl_copy125, "font-size");
  line-height: map-get($pl_copy125, "line-height");
}

%pre-suffix-base {
  height: 24px;
  position: absolute;
  top: 12px;
  margin: 0;
  color: $pl_color-grey400;
  font-size: map-get($pl_copy125, "font-size");
  line-height: map-get($pl_copy125, "line-height");
  opacity: 1;
  transition: opacity 100ms ease-in;
  pointer-events: none;
}

%pl_icon-base {
  @extend %pre-suffix-base;
  width: 24px;
  fill: $pl_color-grey400;
}

.p_input,
.p_input_wrapper,
.pl_input,
.pl_input_wrapper {
  margin-top: 6px; /*                               */
  margin-bottom: 24px;
  position: relative;

  .p_input_wrapper &,
  .pl_input_wrapper & {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__prefix {
    .pl_icon {
      @extend %pl_icon-base;
      left: 16px;
    }
    &.pl_input__note {
      @extend %pre-suffix-base;
      left: 16px;
    }
  }

  &__suffix {
    &.pl_icon {
      @extend %pl_icon-base;
      right: 16px;
    }
    &.pl_input__note {
      @extend %pre-suffix-base;
      right: 16px;
    }
  }

  &__legend {
    margin-bottom: 10px;
    font-size: map-get($pl_copy100, "font-size");
    line-height: map-get($pl_copy100, "line-height");
  }

  &__field {
    @extend %pl_input;
    background-color: transparent;
    border: 1px solid $pl_color-grey200;
    border-radius: $pl_border-radius50;
    color: $pl_color-black100;
    position: relative;
    height: 48px;
    padding: 0 16px;
    width: 100%;

    /*                                                                                               */
    &::-ms-clear {
      display: none;
    }

    &:invalid {
      box-shadow: none;
    }

    &[type="tel"],
    &[type="number"] {
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      /*                                                                  */
      border-color: $pl_color-black100 /*            */;
      outline: 0;
      box-shadow: none;
    }

    /*                   */
    &::placeholder {
      color: transparent;
    }

    &[value=""]:not(:focus) {
      & ~ .pl_input__prefix,
      & ~ .pl_input__suffix {
        opacity: 0;
      }
    }
  }

  &__prefix &__field {
    &:not([value=""]),
    &:focus {
      padding-left: 48px;
    }
  }

  &__suffix &__field {
    &:not([value=""]),
    &:focus {
      padding-right: 48px;
    }
  }

  &__prefix &__field {
    padding-left: 48px;
  }

  &__suffix &__field {
    padding-right: 48px;
  }

  &--invalid {
    @at-root .p_input--invalid {
      .p_input__field {
        border-color: $pl_color-red100;
      }
    }
    @at-root .pl_input--invalid {
      .pl_input__field {
        border-color: $pl_color-red100;
      }
    }

    .p_input__note,
    .pl_input__note {
      color: $pl_color-red100;
    }
  }

  &--success {
    @at-root .pl_input--success {
      .pl_input__field {
        border-color: $pl_color-success;
      }
    }
    .pl_input__note {
      color: $pl_color-success;
    }
  }

  &--hint {
    @at-root .p_input--hint {
      .p_input__field {
        border-color: $hint-inverted-color;
      }
    }
    @at-root .pl_input--hint {
      .pl_input__field {
        border-color: $pl_color-hint;
      }
    }
    .p_input__note {
      color: $hint-inverted-color;
    }
  }

  &--valid {
    @at-root .p_input--valid {
      .p_input__field {
        border-color: $pl_color-success;
      }
    }
    .p_input__note {
      color: $pl_color-success;
    }
  }

  &--warning {
    .p_input__field {
      border-color: $pl_color-hint;
    }
  }

  &--no-floating-label {
    /*                                                                 */
    input.p_input__field::placeholder,
    input.pl_input__field::placeholder {
      color: $pl_color-grey400;
    }
  }

  &--floating-label {
    /*                                                              */
    input.p_input__field[placeholder],
    input.pl_input__field[placeholder] {
      &::placeholder {
        color: $pl_color-grey400;
      }
    }
  }

  &__note {
    display: block;
    margin: 4px 0 0 0;
    color: $pl_color-grey400;
    clear: both;
    font-size: map-get($pl_copy75, "font-size");
    line-height: map-get($pl_copy75, "line-height");

    .p_input_wrapper &,
    .pl_input_wrapper & {
      float: left;
    }
  }
}

.p_input_wrapper:after,
.pl_input_wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.pl_input,
.pl_input.pl_input--success,
.pl_input.pl_input--invalid {
  display: flex;
  flex-wrap: wrap;

  .pl_input__field:disabled {
    color: $pl_color-grey200;
    border-color: $pl_color-grey100;

    & + .pl_label,
    & + .pl_input__note,
    & + .pl_label + .pl_input__note {
      color: $pl_color-grey200;
    }

    & ~ .pl_input__prefix,
    & ~ .pl_input__suffix {
      fill: $pl_color-grey200;
      color: $pl_color-grey200;
    }
  }
}

.pl_input__field-with-placeholder {
  &::placeholder {
    transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: none !important; /*                */
  }

  &:focus {
    &::placeholder {
      color: $pl_color-grey400;
    }
  }
}
