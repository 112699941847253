$pl_textarea_border-width: 1px;
$pl_textarea_padding-y: 12px;
$pl_textarea_element_line-height: map-get($pl_copy125, "line-height");

$pl_textarea_min-height: //
  2 * $pl_textarea_border-width + /*        */
  2 * $pl_textarea_padding-y + /*         */
  3 * $pl_textarea_element_line-height; /*       */

.p_textarea,
.pl_textarea {
  margin: 6px 0 24px 0;
  position: relative;

  display: flex;
  flex-wrap: wrap;

  &__element {
    padding: $pl_textarea_padding-y 16px;
    width: 100%;
    min-height: $pl_textarea_min_height;

    resize: none;

    color: $pl_color-black100;
    outline: none;
    border-radius: $pl_border-radius50;
    display: block;
    border: $pl_textarea_border-width solid $pl_color-grey200;

    font-size: map-get($pl_copy125, "font-size");
    line-height: $pl_textarea_element_line-height;

    /*                   */
    &::placeholder {
      color: transparent;
    }

    &:focus {
      border-color: $pl_color-black100;
    }

    .p_textarea--invalid &,
    .pl_textarea--invalid & {
      border-color: $pl_color-red100;
    }

    .p_textarea--success &,
    .pl_textarea--success & {
      border-color: $pl_color-success;
    }

    .p_textarea--hint &,
    .pl_textarea--hint & {
      border-color: $pl_color-hint;
    }
  }

  &--invalid {
    .p_textarea__note,
    .pl_textarea__note {
      color: $pl_color-red100;
    }
  }

  &--success {
    .p_textarea__note,
    .pl_textarea__note {
      color: $pl_color-success;
    }
  }

  &__note {
    display: block;
    margin-top: 4px;
    color: $pl_color-grey400;
    font-size: map-get($pl_copy75, "font-size");
    line-height: map-get($pl_copy75, "line-height");
    max-width: calc(100% - 100px);

    &:last-child {
      max-width: 100%;
    }
  }

  .pl_textarea__element:disabled {
    color: $pl_color-grey200;
    border-color: $pl_color-grey100;
    background-color: $pl_color-white100;
    & + .pl_textarea__note,
    & + .pl_label,
    & + .pl_label + .pl_textarea__note {
      color: $pl_color-grey200;
    }
  }
}
