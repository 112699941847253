@use "~@otto-ec/global-frontend-settings/gfs";

@use "sass:math";

%pl_grid-containerBase {
  display: flex;
  flex-wrap: wrap;
}

.pl_grid {
  &-lane {
    > .pl_grid-container {
      @extend %pl_grid-containerBase;
      margin-left: -($pl_padding25);
      transform: translateY(-4px);

      > [class^="pl_grid-col"] {
        padding-left: ($pl_padding25);
        padding-top: ($pl_padding25);
      }
    }
  }

  &-content {
    > .pl_grid-container {
      @extend %pl_grid-containerBase;
    }
  }

  &-content100 {
    margin-left: $pl_margin100;
    margin-right: $pl_margin100;

    &.no-outer-spacing {
      margin-left: 0;
      margin-right: 0;
    }

    > .pl_grid-container {
      @extend %pl_grid-containerBase;
      margin-left: -($pl_padding100);
      margin-top: -($pl_padding100);

      > [class^="pl_grid-col"] {
        padding-left: ($pl_padding100);
        padding-top: ($pl_padding100);
      }
    }
  }
}

/*                       */
/*                       */
.pl_grid-lane,
.pl_grid-content,
.pl_grid-content100 {
  > .pl_grid-container {
    @for $i from 1 through 12 {
      .pl_grid-col-#{$i} {
        flex: 0 0 percentage(math.div($i, 12));
        max-width: percentage(math.div($i, 12));
      }
      .pl_grid-offset-#{$i} {
        margin-left: percentage(math.div($i, 12));
      }
    }
  }
}

@include gfs.from-m() {
  .pl_grid-lane,
  .pl_grid-content,
  .pl_grid-content100 {
    > .pl_grid-container {
      @for $i from 1 through 12 {
        .pl_grid-col-md-#{$i} {
          flex: 0 0 percentage(math.div($i, 12));
          max-width: percentage(math.div($i, 12));
        }
        .pl_grid-offset-md-#{$i} {
          margin-left: percentage(math.div($i, 12));
        }
      }
    }
  }
}

@include gfs.from-l() {
  .pl_grid-lane,
  .pl_grid-content,
  .pl_grid-content100 {
    > .pl_grid-container {
      @for $i from 1 through 12 {
        .pl_grid-col-lg-#{$i} {
          flex: 0 0 percentage(math.div($i, 12));
          max-width: percentage(math.div($i, 12));
        }
        .pl_grid-offset-lg-#{$i} {
          margin-left: percentage(math.div($i, 12));
        }
      }
    }
  }
}

@include gfs.from-xl() {
  .pl_grid-lane,
  .pl_grid-content,
  .pl_grid-content100 {
    > .pl_grid-container {
      @for $i from 1 through 12 {
        .pl_grid-col-xl-#{$i} {
          flex: 0 0 percentage(math.div($i, 12));
          max-width: percentage(math.div($i, 12));
        }
        .pl_grid-offset-xl-#{$i} {
          margin-left: percentage(math.div($i, 12));
        }
      }
    }
  }
}
