/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

.p_icons,
.p_iconFont {
  @extend %p_basisSettingsBoxSizingOnly;
  font-family: $iconsFontFamily;
}
