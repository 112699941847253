@use "~@otto-ec/global-frontend-settings/gfs";

@mixin set-hover-and-active-style() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }

  &:active {
    @content;
  }
}

.pl_focussed-dialog-body {
  height: auto;
  background: $pl_color-grey25;
}

$defaultPadding: 3 * $outer-grid-padding;

.pl_focussed-dialog {
  margin: auto;
  min-width: 320px;
  padding-bottom: 32px;

  &__container {
    position: relative;
    background: $pl_color-white100;
    padding: 24px 16px;

    @include gfs.from-m() {
      background: transparent;
      position: static;
      min-height: auto;
      max-width: 600px;
      width: 100%;
      padding: 4 * $outer-grid-padding 0 0;
      margin: 0 auto;
    }
  }

  &__back-button {
    padding: 4px 16px 4px 0;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__header {
    display: flex;

    h1 {
      color: $pl_color-black100;

      @include gfs.from-m() {
        margin-right: 48px;
      }

      .pl_focussed-dialog--inApp & {
        margin-right: 48px;
      }
    }
  }

  &__logo {
    display: block;
    height: 46px;
    position: relative;
    width: 90px;
    margin-bottom: 24px;

    svg {
      fill: $pl_color-red100;
      width: 100%;
      height: 100%;
    }

    @include gfs.from-m() {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
    }
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 32px;
    background-image: url("/assets-static/icons/pl_icon_close.svg#pl_icon_close");

    .pl_focussed-dialog--inApp & {
      top: 28px;
    }

    @include gfs.from-m() {
      top: 26px;

      .pl_focussed-dialog--inApp & {
        top: 26px;
      }
    }

    text-decoration: none;

    @include set-hover-and-active-style {
    }
  }

  &__actions {
    text-align: center;
    display: flex;
    flex-wrap: wrap-reverse;
    position: sticky;
    z-index: 9000;

    /*                                                        */
    /*                                          */
    .pl_focussed-dialog--unsticky & {
      position: static;
    }

    background: $pl_color-white100;

    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
    padding-top: 16px;
    margin-left: -16px;
    margin-right: -16px;

    /*     */
    margin-bottom: -24px;
    padding-bottom: 16px;
    bottom: 0;

    @include gfs.from-m() {
      justify-content: center;
      row-gap: 8px; /*                                        */
      bottom: -8px;

      padding-bottom: 24px;
      margin-bottom: -8px;
    }

    &-item {
      flex: 1 0 50%;

      /*                                                   */
      _:-ms-fullscreen,
      :root & {
        min-width: 50%;
      }

      max-width: 100%;

      padding: 0 4px 8px;

      @include gfs.from-m() {
        flex: 0 1;
        padding: 0 4px;
      }
    }

    button,
    a {
      min-width: 100%;
      max-width: 100%;
      white-space: nowrap;

      @include gfs.from-m() {
        width: auto;
        min-width: 200px;
      }
    }
  }

  &__content {
    margin: 0;
    background-color: $pl_color-white100;
    @include gfs.from-m() {
      margin: 0 auto 0;
      padding: 24px 16px 8px;
      position: relative;
    }

    &--full-bleed {
      background-color: $pl_color-grey25;
      padding: 0;

      > .pl_focussed-dialog__body {
        margin: unset;
      }
    }
  }

  &__body {
    margin: 16px 0 0;

    /*                            */
    &:last-child {
      margin-bottom: 16px;
    }
  }
}
