@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

%p_basisSettingsRoundedStyling {
  background-color: $pl_color-white100;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  outline: 0 none;
  position: relative;

  /*      */
  > svg {
    fill: $pl_color-black100;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    path {
      fill: inherit;
    }
  }
}

.pl_rounded100 {
  @extend %p_basisSettingsRoundedStyling;

  font-size: 16px;
  height: 48px;
  line-height: 24px;
  width: 48px;

  > svg {
    height: 24px;
    width: 24px;
  }

  @mixin rounded-button-100-disabled {
    background-color: $pl_color-grey100;
    cursor: default;

    > svg {
      fill: $pl_color-grey400;

      path {
        fill: inherit;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: $pl_color-grey100;
    }

    &[disabled]:hover {
      @include rounded-button-100-disabled;
    }
  }

  &:active {
    background-color: $pl_color-grey100;
  }

  &[disabled],
  &[disabled]:active {
    @include rounded-button-100-disabled;
  }
}

.pl_rounded50 {
  @extend %p_basisSettingsRoundedStyling;

  font-size: 12px;
  height: 48px;
  line-height: 24px;
  width: 48px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                      */
  background-repeat: no-repeat no-repeat;
  background-position: center center;

  &--elevated {
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
    &:before {
      content: "";
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: block;
      box-shadow: 0 1px 3px 1px rgb(0 0 0 / 15%);
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }

  @mixin rounded-button-50-hover {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                     */
  }

  @mixin rounded-button-50-disabled {
    background-color: transparent;
    background-image: none;
    cursor: default;

    > svg {
      fill: $pl_color-grey400;
    }
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      @include rounded-button-50-hover;
    }

    &[disabled]:hover {
      @include rounded-button-50-disabled;
    }
  }

  &:active {
    @include rounded-button-50-hover;
  }

  &[disabled],
  &[disabled]:active {
    @include rounded-button-50-disabled;
  }

  > svg {
    height: 24px;
    width: 24px;
  }
}
