.p_orderedList100,
.p_unorderedList100 {
  box-sizing: border-box;
  @include font-size($font100, true);
}

.p_unorderedList100 {
  list-style-type: disc;
  margin-left: 1.1em;

  .p_unorderedList100 {
    list-style-type: circle;
  }
}

.p_orderedList100 {
  list-style-type: decimal;
  margin-left: 1.4em;

  .p_orderedList100 {
    list-style-type: lower-latin;
  }
}
