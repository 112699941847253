.p_checkbox,
.pl_checkbox {
  /*                                                                        */
  /*                                                                                                                                 */
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  &:after {
    clear: both;
    content: "";
    display: table;
  }

  &__element {
    -webkit-appearance: none;
    border: none;
    display: inline-block;
    height: 24px;
    margin: 0;
    outline: none;
    padding: 0;
    cursor: pointer;
    width: 24px;

    &:after {
      border-radius: $pl_border-radius25;
      border: 1px solid $pl_color-grey200;
      content: "";
      display: block;
      height: 24px;
      left: 0;
      line-height: 24px;
      position: relative;
      text-align: center;
      top: 0;
      width: 24px;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }

      .p_checkbox--invalid &,
      .pl_checkbox--invalid & {
        border-color: $pl_color-red100;
      }
    }

    &:checked {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http:/*                                                                                                                                                                                                                                                                                                         */
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 12px 12px;
        border-color: $pl_color-grey200 !important;
        display: block;
        height: 24px;
      }
      &:disabled:after {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http:/*                                                                                                                                                                                                                                                                                                                       */
      }
    }
  }

  &__label {
    display: inline-block;
    font-size: map-get($pl_copy125, "font-size");
    line-height: map-get($pl_copy125, "line-height");
    max-width: calc(100% - 40px);
    padding: 0;
    margin-left: 16px;
    cursor: pointer;
    &--full-width {
      @extend .pl_checkbox__label;
      display: flex;
      flex: 1 0 auto;
      justify-content: space-between;
      max-width: none;
    }
    + .pl_checkbox__note {
      margin-top: 5px;
    }
  }

  &__note {
    display: block;
    flex-basis: 100%;
    font-size: map-get($pl_copy75, "font-size");
    line-height: map-get($pl_copy75, "line-height");
  }

  &--hint {
    .p_checkbox__note {
      color: $hint-inverted-color;
    }
  }
  &--invalid {
    .p_checkbox__note,
    .pl_checkbox__note {
      color: $pl_color-red100;
    }
  }
}

.pl_checkbox__element:disabled {
  background-color: $pl_color-grey25;
  border-color: $pl_color-grey100;
  border-radius: $pl_border-radius25;
  & + .pl_checkbox__label,
  & + .pl_checkbox__note,
  & + .pl_checkbox__label + .pl_checkbox__note {
    color: $pl_color-grey200;
  }
}
