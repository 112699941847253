.p_link100--1st {
  cursor: pointer;
  text-decoration: underline;
  @include font-size($font100, true);

  &:hover {
    color: $red100;
  }

  i {
    @include font-size($font75, false);
    display: inline-block;
    font-family: $iconsFontFamily;
    font-style: normal;
    line-height: 1em;
    word-spacing: 6px;
  }
}

.p_link100--2nd {
  box-sizing: border-box;
  @include font-size($font100, true);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $red100;
    text-decoration: underline;
  }

  i {
    display: inline-block;
    font-family: $iconsFontFamily;
    @include font-size($font75, false);
    font-style: normal;
    line-height: 1em;
    word-spacing: 6px;
  }
}

.p_link--1st {
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $red100;
  }

  i {
    display: inline-block;
    font-family: $iconsFontFamily;
    font-size: 0.75em;
    font-style: normal;
  }
}

.p_link--2nd {
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $red100;
    text-decoration: underline;
  }

  i {
    display: inline-block;
    font-family: $iconsFontFamily;
    font-size: 0.75em;
    font-style: normal;
  }
}
