@use "~@otto-ec/global-frontend-settings/gfs";

a.pl_promo-card--link {
  text-decoration: none;
}

.pl_promo-card {
  display: flex;
  flex-direction: column;
  position: relative;

  header {
    display: flex;
    justify-content: space-between;
    padding: $pl_padding100;
    flex: 1 0 auto;
    min-height: 88px;

    @include gfs.from-s(gfs.$breakpoint-l) {
      min-height: 80px;
    }

    .pl_promo-card-headline-container {
      display: flex;
      justify-content: center;
      flex-direction: column;

      p.pl_promo-card-headline-overline {
        margin-bottom: 0; /*                                          */
      }
    }

    .pl_promo-card-header-picture {
      height: 48px;
      flex: 0 0 64px;
      display: flex;
      align-items: center;
      align-self: center;

      img {
        max-height: 100%;
        width: 100%;
        object-fit: contain;
      }

      @include gfs.from-l() {
        flex-grow: 1;
        justify-content: end;

        img {
          width: auto;
          max-width: 348px;
        }
      }
    }
  }

  .pl_promo-card-picture-container {
    flex: 0 1 auto;
    position: relative;
    overflow: hidden;

    .pl_promo_card-picture {
      display: block;

      img {
        display: block;
        width: 100%;
      }
    }

    .pl_promo-card-tag-container {
      position: absolute;
      bottom: 0;
      padding: $pl_padding100;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      *[class^="pl_tag"] {
        margin-top: 8px;
      }
    }
  }

  footer {
    background-color: $pl_color-white100;
    padding: $pl_padding100;
    min-height: 80px;

    p.pl_promo-card-headline-underline {
      margin-bottom: 0; /*                                          */
    }
  }
}
