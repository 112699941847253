/*         */
/*                   */
/*  */
.p_btn150--1st,
.p_btn150--2nd,
.p_btn150--3rd,
.p_btn150--4th,
.p_btn100--1st,
.p_btn100--2nd,
.p_btn100--3rd,
.p_btn100--4th,
.p_btn50--1st,
.p_btn50--2nd,
.p_btn50--3rd,
.p_btn50--4th {
  box-sizing: border-box;
  @include font-size($font100, true);
  background-clip: padding-box;
  border: medium none;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  margin-bottom: 0;
  margin-top: 0;
  outline: 0 none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  vertical-align: baseline;
  width: 100%;

  /*          */
  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    color: $disabled-color;
    cursor: default;
  }
  /*      */
  i {
    @include font-size($font175, false);
    display: inline-block;
    font-family: $iconsFontFamily !important;
    font-style: normal;
    vertical-align: bottom;
    word-spacing: 6px;
  }
}

/*                                                                         */
@mixin set-hover-and-active-style() {
  .not-touchable & {
    &:hover {
      @content;
    }
  }

  .touchable & {
    &:active {
      @content;
    }
  }
}

/*                        */
/*  */
.p_btn150--1st,
.p_btn100--1st,
.p_btn50--1st {
  background: $red100;
  color: $grey25;

  @include set-hover-and-active-style {
    background: $red50;
    color: $white;
  }

  &[disabled],
  &[disabled]:hover {
    background: $disabled-color;
    color: #eeeeee;
  }
}

/*  */
.p_btn150--2nd,
.p_btn100--2nd,
.p_btn50--2nd {
  box-sizing: border-box;
  @include font-size($font100, true);
  background: #666666;
  color: $grey25;

  @include set-hover-and-active-style {
    background: $grey400;
    color: $white;
  }

  &[disabled],
  &[disabled]:hover {
    background: $disabled-color;
    color: #eeeeee;
  }
}

/*  */
.p_btn150--3rd,
.p_btn100--3rd,
.p_btn50--3rd {
  background-color: $grey25;
  border: 1px solid $grey200;
  color: $black50;

  @include set-hover-and-active-style {
    background: #fefefe;
    color: #d5281e;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: none;
    border-color: $disabled-color;
    color: $disabled-color;
  }
}

/*  */
.p_btn150--4th,
.p_btn100--4th,
.p_btn50--4th {
  background: none;
  color: $black50;
  text-decoration: underline;

  @include set-hover-and-active-style {
    background: $grey50;
    color: #d5281e;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: $white;
    border-color: $disabled-color;
    color: $disabled-color;
  }
}

/*     */
/*  */
.p_btn150--1st,
.p_btn150--2nd,
.p_btn150--3rd,
.p_btn150--4th {
  @include font-size($font125, true);
  min-height: 50px;
  padding: 10px 20px;
}

/*                                   */
a {
  &.p_btn150 {
    &--1st,
    &--2nd,
    &--3rd,
    &--4th {
      line-height: 1.75em;
    }
  }
}

/*            */
.p_btn150--3rd {
  padding: 9px 19px;
}

/*     */
/*  */
.p_btn100--1st,
.p_btn100--2nd,
.p_btn100--3rd,
.p_btn100--4th {
  min-height: 40px;
  padding: 10px 20px;
}

/*            */
.p_btn100--3rd {
  padding: 9px 19px;
}

/*    */
/*  */
.p_btn50--1st,
.p_btn50--2nd,
.p_btn50--3rd,
.p_btn50--4th {
  @include font-size($font75, true);
  min-height: 30px;
  padding: 6px 10px;
}

/*      */
.p_btn150--1st i,
.p_btn150--2nd i,
.p_btn150--3rd i,
.p_btn150--4th i {
  @include font-size($font225, false);
}

.p_btn50--1st i,
.p_btn50--2nd i,
.p_btn50--3rd i,
.p_btn50--4th i {
  font-size: 17px;
  font-size: 1.25em;
}

/*                  */
.p_btn50--3rd {
  padding: 5px 9px;
}
