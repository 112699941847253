/*                                                                         */
@mixin set-hover-and-active-style() {
  .not-touchable & {
    &:hover {
      @content;
    }
  }

  .touchable & {
    &:active {
      @content;
    }
  }
}

.p_symbolBtn150--1st,
.p_symbolBtn150--2nd,
.p_symbolBtn150--3rd,
.p_symbolBtn150--4th,
.p_symbolBtn150--5th,
.p_symbolBtn100--1st,
.p_symbolBtn100--2nd,
.p_symbolBtn100--3rd,
.p_symbolBtn100--4th,
.p_symbolBtn100--5th,
.p_symbolBtn50--1st,
.p_symbolBtn50--2nd,
.p_symbolBtn50--3rd,
.p_symbolBtn50--4th,
.p_symbolBtn50--5th,
.p_symbolBtn25--1st,
.p_symbolBtn25--2nd,
.p_symbolBtn25--3rd,
.p_symbolBtn25--4th,
.p_symbolBtn25--5th,
.p_symbolBtn10--3rd {
  box-sizing: border-box;
  @include font-size($font100, true);
  background-clip: padding-box;
  background-repeat: repeat-x;
  border: medium none;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  outline: 0 none;
  overflow: visible;
  padding: 0 !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
  vertical-align: bottom;

  /*       */
  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    color: $disabled-color;
    cursor: default;
  }

  /*   */
  i {
    font-family: $iconsFontFamily !important;
    font-style: normal;
  }
}

/*                             */
/*                     */
.p_symbolBtn150--1st,
.p_symbolBtn100--1st,
.p_symbolBtn50--1st,
.p_symbolBtn25--1st {
  background: $red100;
  color: $grey25;

  @include set-hover-and-active-style {
    background: $red50;
    color: $white;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: $disabled-color;
    color: #eeeeee;
  }
}

/*  */
.p_symbolBtn150--2nd,
.p_symbolBtn100--2nd,
.p_symbolBtn50--2nd,
.p_symbolBtn25--2nd {
  background: #796d6a;
  background: linear-gradient(to bottom, #666666, #796d6a);
  color: $grey25;

  @include set-hover-and-active-style {
    background: $grey400;
    color: $white;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: $disabled-color;
    color: #eeeeee;
  }
}

/*  */
.p_symbolBtn150--3rd,
.p_symbolBtn100--3rd,
.p_symbolBtn50--3rd,
.p_symbolBtn25--3rd,
.p_symbolBtn10--3rd {
  background-color: $grey25;
  border: 1px solid $grey200;
  color: $black50;

  @include set-hover-and-active-style {
    background: #fefefe;
    color: #d5281e;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: none;
    color: $disabled-color;
  }
}

/*  */
.p_symbolBtn150--4th,
.p_symbolBtn100--4th,
.p_symbolBtn50--4th,
.p_symbolBtn25--4th {
  background: none;
  color: $black50;

  @include set-hover-and-active-style {
    background: $grey50;
    color: #d5281e;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: $white;
    border-color: $disabled-color;
    color: $disabled-color;
  }
}

/*  */
.p_symbolBtn150--5th,
.p_symbolBtn100--5th,
.p_symbolBtn50--5th,
.p_symbolBtn25--5th {
  background: transparent;
  color: $white;
  text-shadow: 1px 2px 8px $black100;

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: transparent;
    border-color: $disabled-color;
    color: $disabled-color;
    text-shadow: none;
  }
}

/*     */
/*  */
.p_symbolBtn150--1st,
.p_symbolBtn150--2nd,
.p_symbolBtn150--3rd,
.p_symbolBtn150--4th,
.p_symbolBtn150--5th {
  @include font-size($font300, false);
  height: 50px;
  line-height: 50px;
  width: 50px;
}

/*            */
.p_symbolBtn150--3rd {
  line-height: 48px;
}

/*     */
/*  */
.p_symbolBtn100--1st,
.p_symbolBtn100--2nd,
.p_symbolBtn100--3rd,
.p_symbolBtn100--4th,
.p_symbolBtn100--5th {
  @include font-size($font225, false);
  height: 40px;
  line-height: 40px;
  width: 40px;
}

/*            */
.p_symbolBtn100--3rd {
  line-height: 38px;
}

/*    */
/*  */
.p_symbolBtn50--1st,
.p_symbolBtn50--2nd,
.p_symbolBtn50--3rd,
.p_symbolBtn50--4th,
.p_symbolBtn50--5th {
  @include font-size($font150, false);
  height: 30px;
  line-height: 30px;
  width: 30px;
}
.p_symbolBtn25--5th {
  font-size: 20px;
  @include font-size(
    (
      font-size: 20px,
      line-height: 20px,
    ),
    false
  );
}

.p_symbolBtn50--5th {
  @include font-size(
    (
      font-size: 30px,
      line-height: 30px,
    ),
    false
  );
}
.p_symbolBtn100--5th {
  @include font-size(
    (
      font-size: 40px,
      line-height: 40px,
    ),
    false
  );
}
.p_symbolBtn150--5th {
  @include font-size(
    (
      font-size: 50px,
      line-height: 50px,
    ),
    false
  );
}

/*            */
.p_symbolBtn50--3rd {
  line-height: 28px;
}

/*    */
/*  */
.p_symbolBtn25--1st,
.p_symbolBtn25--2nd,
.p_symbolBtn25--3rd,
.p_symbolBtn25--4th,
.p_symbolBtn25--5th {
  height: 20px;
  line-height: 20px;
  width: 20px;
}

/*            */
.p_symbolBtn25--3rd {
  line-height: 18px;
}

/*    */
.p_symbolBtn10--3rd {
  height: 14px;
  width: 14px;
  line-height: 13px;
  @include font-size(
    (
      font-size: 12px,
      line-height: 13px,
    ),
    true
  );
}
