/*                                             */
$select-base-gap-x: 12px;
$select-gap-x: calc(#{$select-base-gap-x} - 8px);
$select-gap-x_edge: calc(#{$select-base-gap-x} - 3px);
$select-gap-x_ie: calc(#{$select-base-gap-x} - 2px);

.p_dropdown,
.p_dropdown_wrapper,
.pl_dropdown,
.pl_dropdown_wrapper {
  position: relative;
  margin-top: 6px; /*                               */
  margin-bottom: 24px;

  .p_dropdown_wrapper &,
  .pl_dropdown_wrapper & {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__element {
    appearance: none;
    cursor: pointer;
    background-color: $white;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http:/*                                                                                                                                                                                                                                                                                                                      */
    background-position: right 12px center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    border: 1px solid $pl_color-grey200;
    border-radius: $pl_border-radius50;
    font-size: map-get($pl_copy125, "font-size");
    line-height: map-get($pl_copy125, "line-height");
    display: block;
    height: 48px;
    margin: 0;
    text-indent: 5px;
    outline: none;
    padding: 8px 40px 8px $select-gap-x;
    vertical-align: baseline;
    width: 100%;
    color: $pl_color-black100;

    /*                                                                 */
    &[data-value=""] {
      color: $pl_color-grey400;
    }

    &::-ms-expand {
      display: none;
    }

    &:focus {
      border-color: $pl_color-black100;
    }

    option {
      color: $pl_color-black100;
      padding: 2px 8px;
      /**
 *
 *
 *
 */
      &[data-value=""] {
        color: $pl_color-grey400;
      }
    }

    option:disabled {
      color: $pl_color-grey200;
    }

    &[data-value][data-value=""] {
      .p_dropdown--invalid &,
      .pl_dropdown--invalid & {
        border-color: $pl_color-red100;
        color: $pl_color-red100;

        option:disabled {
          color: $pl_color-grey200;
        }
      }
    }
    /*                                                      */
    &[data-value]:not([data-value=""]),
    &[data-value]:not([data-value=""]) {
      + .p_label,
      + .pl_label {
        z-index: 0;
      }

      .p_dropdown--invalid &,
      .pl_dropdown--invalid & {
        + .p_label,
        + .pl_label {
          color: $pl_color-grey400;

          + .p_dropdown__note,
          + .pl_dropdown__note {
            color: $pl_color-grey400;
          }
        }
      }
    }
  }

  &__legend {
    margin-bottom: 10px;
    @include font-size($font125, true);
  }

  .p_label,
  .pl_label {
    /*                                                                                  */
    z-index: -1;
    transition: none;
  }

  &--invalid {
    .p_dropdown__note,
    .pl_dropdown__note {
      color: $pl_color-red100;
    }
  }

  &__note {
    clear: both;
    display: block;
    margin: 4px 0 0 0;
    color: $pl_color-grey400;
    font-size: map-get($pl_copy75, "font-size");
    line-height: map-get($pl_copy75, "line-height");

    .p_dropdown_wrapper &,
    .pl_dropdown_wrapper & {
      float: left;
    }
  }

  &--float {
    float: left;
    margin-right: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

/*            */
@supports (-ms-ime-align: auto) {
  .p_dropdown__element,
  .pl_dropdown__element {
    padding-left: $select-gap-x_edge;
  }
}

/*                            */
@supports (-webkit-appearance: none) and (shape-outside: none) {
  .p_dropdown__element,
  .pl_dropdown__element {
    /*                                                                       */
    padding-left: $select-base-gap-x;
  }
}

/*                 */
_::-webkit-full-page-media,
_:future,
:root .p_dropdown__element,
:root .pl_dropdown__element {
  /*                                                                       */
  padding-left: $select-base-gap-x;
}

/*         */
@-moz-document url-prefix() {
  .p_dropdown__element,
  .pl_dropdown__element {
    /*                                                                              */
    padding-left: $select-gap-x;
  }
}

.p_dropdown_wrapper:after,
.pl_dropdown_wrapper:after {
  clear: both;
  content: "";
  display: table;
}

.pl_dropdown,
.pl_dropdown.pl_dropdown--invalid {
  .pl_dropdown__element:disabled {
    color: $pl_color-grey200;
    cursor: default;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http:/*                                                                                                                                                                                                                                                                                                                                    */
    border-color: $pl_color-grey100;
    & + .pl_label,
    & + .pl_dropdown__note,
    & + .pl_label + .pl_dropdown__note {
      color: $pl_color-grey200;
    }
  }
}
