.p_line100,
.p_line200 {
  box-sizing: border-box;
  @include font-size($font100, true);
  border: none;
  height: 0;
}

/*             */
.p_line100 {
  border-bottom: 1px solid $grey200;
}

.p_line200 {
  border-bottom: 2px solid $grey200;
}
