/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

.p_rating200,
.p_rating100,
.p_rating50 {
  @extend %p_basisSettingsBoxSizingOnly;
  font-family: $iconsFontFamily;
  vertical-align: sub;
}

.p_rating200 {
  @include font-size($font250, false);
  line-height: 0.7em;
  letter-spacing: -0.075em;
}

.p_rating100 {
  @include font-size($font175, false);
  line-height: 0.6em;
  letter-spacing: -0.075em;
}

.p_rating50 {
  @include font-size($font125, false);
  letter-spacing: -0.1em;
}
