/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

.pl_copy50 {
  @extend %p_basisSettingsBoxSizingOnly;
  font-family: $sansFontFamily;
  font-size: map-get($pl_copy50, "font-size");
  line-height: map-get($pl_copy50, "line-height");
}

.pl_copy75 {
  @extend %p_basisSettingsBoxSizingOnly;
  font-family: $sansFontFamily;
  font-size: map-get($pl_copy75, "font-size");
  line-height: map-get($pl_copy75, "line-height");

  &--grey {
    color: $grey400;
  }
}

.pl_copy100 {
  @extend %p_basisSettingsBoxSizingOnly;
  font-family: $sansFontFamily;
  font-size: map-get($pl_copy100, "font-size");
  line-height: map-get($pl_copy100, "line-height");

  &--grey {
    color: $grey400;
  }
}

.pl_copy125 {
  @extend %p_basisSettingsBoxSizingOnly;
  font-family: $sansFontFamily;
  font-size: map-get($pl_copy125, "font-size");
  line-height: map-get($pl_copy125, "line-height");
}
