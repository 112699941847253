@use "../../common/scss/focus.scss";

@mixin selected-state {
  font-weight: bold;
  background-color: lighten($pl_color-blue100, 35);

  .pl_chip_check-container,
  .pl_chip_close-container {
    display: flex;
    align-items: center;
  }

  .pl_chip_image-container {
    display: none;
  }
}

@mixin standard-chip {
  border-radius: $pl_border-radius100;
  height: 32px;
  font-size: map-get($pl_copy75, "font-size");
  line-height: map-get($pl_copy75, "line-height");
}

%chipBase {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  vertical-align: middle;
  font-family: $sansFontFamily;
  user-select: none;

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }

  @media (hover: hover) {
    input[type="radio"],
    input[type="checkbox"] {
      /*                                                    */
      display: unset;

      appearance: none;
      margin: 0;

      @supports not (appearance: none) {
        width: 0;
        height: 0;
        /*                                   */
        /*                                                                                              */
        position: fixed;
        opacity: 0;
      }
    }

    input:hover,
    label:hover {
      cursor: pointer;
    }

    input:focus-visible:not(:active) + label {
      @include focus.common-styles;
    }

    @supports not selector(*:focus-visible) {
      input:focus:not(:active) + label {
        @include focus.common-styles;
        /*                                                                                 */
        /*                                           */
        outline-style: auto;
      }
    }

    input:checked + label:hover {
      background: #7dc7ff;
    }
  }

  input:active + label,
  input:active + span {
    background: #4ab1ff;
  }

  .pl_chip_check-container,
  .pl_chip_close-container {
    display: none;
  }

  .pl_chip_check-container,
  .pl_chip_image-container,
  .pl_chip_image-container--visible {
    margin-right: $pl_margin25;
  }

  .pl_chip_close-container,
  .pl_icon_close {
    margin-left: $pl_margin25;
  }

  .pl_chip_image-container,
  .pl_chip_image-container--visible {
    display: flex;
  }

  input:checked + label,
  input:checked + span {
    @include selected-state;
  }
}

%chipSpecs {
  @include standard-chip;
  padding: 0 $pl_margin75;

  span {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg:first-child {
    margin-left: -$pl_margin25;
    margin-right: $pl_margin50;
  }
  svg:last-child {
    margin-left: $pl_margin50;
    margin-right: -$pl_margin25;
  }

  svg:only-child {
    margin-left: -$pl_margin25;
    margin-right: -$pl_margin25;
  }

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
}

%chip100Specs {
  @extend %chipBase;
  @include standard-chip;

  padding: $pl_padding50 10px;

  .pl_chip_image-container,
  .pl_chip_image-container--visible {
    > img {
      top: 2px;
      width: 24px;
      height: 24px;
      border-radius: 16px;
      margin-left: -6px;
    }
  }

  label,
  span {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
  }

  label {
    height: 32px;
    padding: $pl_padding50 10px;
    margin: (-$pl_padding50) -10px;
    border-radius: $pl_border-radius100;
    flex-grow: 1;
    justify-content: center;
  }
}

%chip200Specs {
  @extend %chipBase;

  font-size: map-get($pl_copy100, "font-size");
  border-radius: 24px;
  height: 48px;
  line-height: map-get($pl_copy100, "line-height");
  padding: $pl_padding50 $pl_padding100;

  .pl_chip_check-container {
    margin-right: $pl_margin50;
    margin-left: -$pl_margin25;
  }

  .pl_chip_close-container,
  .pl_icon_close {
    margin-left: $pl_margin50;
    margin-right: -$pl_margin25;
  }

  .pl_chip_image-container,
  .pl_chip_image-container--visible {
    top: 4px;
    margin-right: $pl_margin50;
    > img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-left: -$pl_margin75;
    }

    > svg {
      margin-left: -$pl_margin25;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  label,
  span {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
  }

  label {
    height: 48px;
    padding: $pl_padding50 $pl_padding100;
    margin: (-$pl_padding50) (-$pl_padding100);
    border-radius: 24px;
    flex-grow: 1;
    justify-content: center;
  }
}

%grey {
  background-color: $pl_color-grey25;

  @media (hover: hover) {
    &:hover {
      background-color: darken($pl_color-grey25, 10);
    }
  }

  &:active {
    background-color: darken($pl_color-grey25, 20);
  }

  input:disabled + label,
  input:disabled + span {
    background-color: $pl_color-grey25;
    cursor: default;
    color: $pl_color-grey400;

    svg {
      fill: $pl_color-grey400;
    }

    img {
      opacity: 77%;
    }
  }
}

%white {
  background-color: $pl_color-white100;

  @media (hover: hover) {
    &:hover {
      background-color: darken($pl_color-white100, 10);
    }
  }

  &:active {
    background-color: darken($pl_color-white100, 20);
  }

  input:disabled + label,
  input:disabled + span {
    background-color: $pl_color-white100;
    cursor: default;
    color: $pl_color-grey200;

    svg {
      fill: $pl_color-grey200;
    }

    img {
      opacity: 47%;
    }
  }
}

.pl_chip100 {
  @extend %chipBase;
  @extend %chip100Specs;
  &--grey {
    @extend %grey;
  }

  &--white {
    @extend %white;
  }
}

.pl_chip200 {
  @extend %chip200Specs;
  &--grey {
    @extend %grey;
  }

  &--white {
    @extend %white;
  }
}
