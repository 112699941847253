@use "../../common/scss/focus.scss";

%pl_basisSettingsLinkStyling {
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;

  @media (hover: hover) {
    &[disabled]:hover {
      cursor: default;
    }
  }

  &[disabled],
  &[disabled]:active {
    cursor: default;
  }
  @include focus.styles {
    border-radius: 2px;
  }
}

%pl_link50FontSize {
  font-size: map-get($pl_copy50, "font-size");
  line-height: map-get($pl_copy50, "line-height");
}

%pl_link75FontSize {
  font-size: map-get($pl_copy75, "font-size");
  line-height: map-get($pl_copy75, "line-height");
}

%pl_link100FontSize {
  font-size: map-get($pl_copy100, "font-size");
  line-height: map-get($pl_copy100, "line-height");
}

%pl_link125FontSize {
  font-size: map-get($pl_copy125, "font-size");
  line-height: map-get($pl_copy125, "line-height");
}

.pl_link--primary {
  @extend %pl_basisSettingsLinkStyling;
  color: $pl_color-blue100;

  @media (hover: hover) {
    &:hover {
      color: darken($pl_color-blue100, 15);
    }
  }

  &:active {
    color: darken($pl_color-blue100, 30);
  }
}

.pl_link50--primary {
  @extend .pl_link--primary;
  @extend %pl_link50FontSize;
}

.pl_link75--primary {
  @extend .pl_link--primary;
  @extend %pl_link75FontSize;
}

.pl_link100--primary {
  @extend .pl_link--primary;
  @extend %pl_link100FontSize;
}

.pl_link125--primary {
  @extend .pl_link--primary;
  @extend %pl_link125FontSize;
}

.pl_link--secondary {
  @extend %pl_basisSettingsLinkStyling;
  color: $pl_color-grey400;

  @media (hover: hover) {
    &:hover {
      color: darken($pl_color-grey400, 15);
    }
  }

  &:active {
    color: darken($pl_color-grey400, 30);
  }
}

.pl_link50--secondary {
  @extend .pl_link--secondary;
  @extend %pl_link50FontSize;
}

.pl_link75--secondary {
  @extend .pl_link--secondary;
  @extend %pl_link75FontSize;
}

.pl_link100--secondary {
  @extend .pl_link--secondary;
  @extend %pl_link100FontSize;
}

.pl_link125--secondary {
  @extend .pl_link--secondary;
  @extend %pl_link125FontSize;
}

.pl_link--underline {
  @extend %pl_basisSettingsLinkStyling;
  text-decoration: underline;

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }

  &:active {
    opacity: 0.5;
  }
}

.pl_link50--underline {
  @extend .pl_link--underline;
  @extend %pl_link50FontSize;
}

.pl_link75--underline {
  @extend .pl_link--underline;
  @extend %pl_link75FontSize;
}

.pl_link100--underline {
  @extend .pl_link--underline;
  @extend %pl_link100FontSize;
}

.pl_link125--underline {
  @extend .pl_link--underline;
  @extend %pl_link125FontSize;
}

/*                                                                             */
button.pl_link--primary,
button.pl_link--secondary,
button.pl_link--underline,
button.pl_link50--primary,
button.pl_link50--secondary,
button.pl_link50--underline,
button.pl_link75--primary,
button.pl_link75--secondary,
button.pl_link75--underline,
button.pl_link100--primary,
button.pl_link100--secondary,
button.pl_link100--underline,
button.pl_link125--primary,
button.pl_link125--secondary,
button.pl_link125--underline {
  background: none;
  border: none;
  padding: 0;
}
