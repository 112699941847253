@charset "UTF-8";

/*              */
button.p_scrollTopButton {
  transition: opacity 0.5s ease;
  position: fixed;
  top: 50px;
  width: auto;
  z-index: 3000;

  &.p_scrollTopButton--hidden {
    opacity: 0;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    visibility: hidden;
  }
}
