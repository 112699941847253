%pl_blockBase {
  width: 100%;
  background-color: $pl_color-white100;
}

.pl_block {
  &:empty {
    padding: 0;
  }

  @extend %pl_blockBase;
  padding: 24px 16px;

  &--full-bleed {
    @extend %pl_blockBase;
    padding: 24px 0;
  }
}

.pl_block--no-padding {
  @extend %pl_blockBase;
  padding: 0;
}
