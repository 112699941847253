@import "@otto-ec/global-frontend-settings/all";

$pl_carousel-animation: ease-in-out;

.pl_carousel {
  margin: 0 auto;
  /*                      */
  position: relative;

  /*    */
  //
  & &__stage {
    scroll-behavior: auto;
    overflow: hidden;
    display: flex;
  }

  &--ready &__stage {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;

    /*                                    */
    @supports (scroll-snap-type: x mandatory) {
      scroll-snap-type: x mandatory;
    }

    /*                                    */
    @supports (scroll-behavior: smooth) {
      scroll-behavior: smooth;
    }

    /*                                    */
    @supports (scroll-behavior: smooth) and (scroll-snap-type: x mandatory) {
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;
    }

    &::-webkit-scrollbar {
      display: none; /*                */
    }
  }

  /*                                                                           */
  &--no-scroll-smoothing &__stage {
    scroll-behavior: unset !important;
  }

  &[data-carousel-mode="cinema"] &__stage {
    flex-direction: row;
    overscroll-behavior-x: none;

    /*                                                                                          */
    &::before,
    &::after {
      content: "";
      flex: 0 0 16px;
    }

    &--no-rubberband {
      overflow-x: hidden !important;
    }
  }

  &[data-carousel-no-edges]:not([data-carousel-no-edges="false"]) &__stage {
    /*                                             */
    &::before,
    &::after {
      content: none;
      flex: 0;
    }
  }

  & &__slide {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
    margin-right: 16px;
    min-width: 100%;
    height: 299px;

    &--active {
      opacity: 1;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &--fallback &__slide {
    opacity: 0.8;
    transition: opacity 0.2s $pl_carousel-animation;
  }

  &[data-carousel-mode="cinema"] &__slide {
    scroll-snap-align: none;
    scroll-snap-stop: normal;
  }

  /*     */
  //
  & &__arrow {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: auto; /*                                  */
    right: 0;
    opacity: 0;

    svg {
      fill: #222;
    }

    &:not(&--airbag) {
      transition: visibility 0.15s $pl_carousel-animation, opacity 0.15s $pl_carousel-animation;
    }

    &--hidden {
      cursor: default !important; /*                     */
      display: none;
    }

    &--airbag {
      opacity: 0;
      display: block;
      visibility: visible;
    }

    &--next {
      left: auto;
    }
  }

  &--no-arrows &__arrow {
    display: none !important;
  }

  /*                                             */
  /*                                          */
  &--ready:not([data-carousel-no-always-on-arrows]) &__arrow:not(&__arrow--hidden),
  &--ready:not([data-carousel-no-always-on-arrows="false"]):hover &__arrow:not(&__arrow--hidden),
  &--ready[data-carousel-no-always-on-arrows]:hover &__arrow:not(&__arrow--hidden) {
    .not-touchable &,
    .touchable-hybrid & {
      visibility: visible;
      opacity: 1;
    }
  }

  /*             */
  //
  & &__page-indicator {
    padding: 0;
    margin: 0 auto;
    display: flex;

    &-item {
      cursor: pointer;

      button {
        border-radius: 50%;
        border: none;
        padding: 0;
        margin: 0 2px;
        width: 8px;
        height: 8px;
        cursor: pointer;
        background-color: $pl_color-grey200;
        transform: scale(0.5);
      }

      &--big {
        button {
          transform: scale(1);
        }
      }

      &:hover {
        button {
          background-color: $pl_color-grey400;
        }
      }

      &--active,
      &--active:hover {
        cursor: default; /*                                                  */
        button {
          background-color: $pl_color-black100;
          cursor: default;
        }
      }
    }

    &-flex {
      max-width: 7 * 12px;
      display: flex;
      margin: 0 auto;
      overflow: hidden;
      position: relative; /*                    */
    }

    &-wrapper {
      margin: 0 auto;
      display: flex;
    }
  }

  &--ready &__page-indicator {
    transition: transform 0.2s $pl_carousel-animation;

    button {
      transition: background-color 0.1s $pl_carousel-animation,
        transform 0.1s $pl_carousel-animation;
    }
  }

  &--no-arrows &__page-indicator {
    display: none !important;
  }

  &--no-scroll-smoothing &__page-indicator {
    transition: none !important;

    button {
      transition: none !important;
    }
  }
}
