@import "pl_selectiontile-rounded_colors";
$border: 1px;

.pl_selectiontile-text100 {
  max-width: 100%;
  display: inline-block;

  input[type="radio"],
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  @media (hover: hover) {
    input:hover,
    label:hover {
      cursor: pointer;
    }
  }

  input:checked + label {
    border-color: $pl_color-black100;
  }

  input:disabled + label {
    color: $pl_color-grey200;
    border-color: $pl_color-grey200;
    cursor: default;
  }

  label {
    @extend .pl_copy100;
    display: block;
    cursor: pointer;
    border: solid $border $pl_color-grey200;
    border-radius: $pl_border-radius50;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    padding: $pl_padding75 - $border $pl_padding100 - $border;
    height: 48px;
    min-width: 48px;
  }
}

.pl_selectiontile-rounded100 {
  display: inline-block;

  input[type="radio"],
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  @media (hover: hover) {
    input:hover,
    label:hover {
      cursor: pointer;
    }
  }

  input[type="radio"] + label,
  input[type="checkbox"] + label {
    display: block;
    border-radius: 16px;
    border: solid $border $pl_color-grey200;
    height: 32px;
    width: 32px;
    line-height: 0;
    cursor: pointer;
  }

  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {
    border-color: $pl_color-black100;
    box-shadow: inset 0 0 0 2px $pl_color-white100;
  }
}
