/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

@mixin darken-arrow-down {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                                     */
  }
}

@mixin darken-arrow-up {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                                          */
  }
}

%pl_expander__linkBase {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                         */
    content: "Mehr anzeigen";
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: right;
    display: block;
    width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      @include darken-arrow-down;
    }
  }

  &:active {
    @include darken-arrow-down;
  }
}
%pl_expander__linkExpandedBase {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                              */
    content: "Weniger anzeigen";
  }

  @media (hover: hover) {
    &:hover {
      @include darken-arrow-up;
    }
  }

  &:active {
    @include darken-arrow-up;
  }
}

/*                                                       */
.pl_expander {
  @extend %p_basisSettingsBoxSizingOnly;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 140px;

  &__toggle {
    bottom: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
  }

  &__link {
    position: absolute;
    bottom: 0;
    width: 100%;

    @extend %pl_expander__linkBase;
  }

  &--expanded.pl_expander {
    .pl_expander__toggle {
      background: $pl_color-white100;
      min-height: 40px;
    }
  }
}

.pl_expander--expanded.pl_expander {
  height: auto;

  .pl_expander__toggle {
    position: relative;
  }

  .pl_expander__link {
    @extend %pl_expander__linkExpandedBase;
  }
}

/*                        */
.pl_expander {
  &__toggle {
    background: linear-gradient(
      0deg,
      rgba($pl_color-white100, 1) 40px,
      rgba($pl_color-white100, 0) 100%
    );
    min-height: 140px; /*                                              */
  }
}

/*                           */
.pl_expander--no-fade__link {
  @extend %pl_expander__linkBase;
  &.pl_expander--no-fade__extended {
    @extend %pl_expander__linkExpandedBase;
  }
}
