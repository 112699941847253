/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

/*                */
.p_textExpander100 {
  @extend %p_basisSettingsBoxSizingOnly;
  height: 110px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.p_textExpander100__toggle {
  background: $white;
  bottom: 0;
  min-height: 40px; /*                                      */
  position: absolute;
  width: 100%;
}

.p_textExpander100--fade {
  .p_textExpander100__toggle {
    background: linear-gradient(
      180deg,
      rgba($white, 0) 0,
      rgba($white, 0.4) 8%,
      rgba($white, 1) 58%
    );
    min-height: 70px; /*                                                     */
  }

  &.p_expander--decrease .p_textExpander100__toggle {
    background: $white;
    min-height: 40px; /*                                         */
  }
}

/*             */
.no-cssgradients .p_textExpander100--fade .p_textExpander100__toggle {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CCFFFFFF', endColorstr='#FFFFFFFF', GradientType=0);
}

.p_textExpander100__link {
  box-sizing: border-box;
  @include font-size($font100, true);
  bottom: 0;
  cursor: pointer;
  padding: 10px 10px 10px 0;
  position: absolute;
  text-decoration: underline;

  &:hover {
    color: $red100;
  }

  i {
    @include font-size($font75, false);
    display: inline-block;
    font-family: $iconFontFamily;
    font-style: normal;
    line-height: 1em;
    word-spacing: 6px;
  }
}

.p_textExpander100__link:after {
  content: attr(data-expand);
}

.p_expander--decrease.p_textExpander100 {
  height: auto;

  .p_textExpander100__toggle {
    position: relative;
  }

  .p_textExpander100__link:after {
    content: attr(data-collapse);
  }
}
