.pl_banner {
  @extend .pl_copy100;
  display: flex;
  border-radius: $pl_border-radius10;

  span {
    flex: 1 1 auto;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-top: 12px;

    &:last-child {
      padding-right: 12px;
    }
  }

  svg {
    flex-shrink: 0;
    cursor: pointer;
    padding: 12px;
    width: 48px;
    height: 48px;
  }
}

.pl_banner--error {
  background-color: $pl_color-red100;
  color: $pl_color-white100;

  svg {
    fill: $pl_color-white100;
  }
}

.pl_banner--hint {
  background-color: $pl_color-hint;
  color: $pl_color-black100;

  svg {
    fill: $pl_color-black100;
  }
}

.pl_banner--info {
  background-color: $pl_color-blue100;
  color: $pl_color-white100;

  svg {
    fill: $pl_color-white100;
  }
}

.pl_banner--success {
  background-color: $pl_color-success;
  color: $pl_color-white100;

  svg {
    fill: $pl_color-white100;
  }
}
