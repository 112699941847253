/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

.pl_display100 {
  @extend %p_basisSettingsBoxSizingOnly;
  font-family: $sansFontFamily;
  font-size: map-get($pl_display100, "font-size");
  font-weight: map-get($pl_display100, "font-weight");
  line-height: map-get($pl_display100, "line-height");
}

.pl_display200 {
  @extend %p_basisSettingsBoxSizingOnly;
  font-family: $sansFontFamily;
  font-size: map-get($pl_display200, "font-size");
  font-weight: map-get($pl_display200, "font-weight");
  line-height: map-get($pl_display200, "line-height");
}
