.pl_accordion {
  margin: -12px 0;
  overflow: hidden;

  > .pl_accordion__header {
    background-color: $pl_color-white100;
    font-size: map-get($pl_headline50, "font-size");
    font-weight: map-get($pl_headline50, "font-weight");
    line-height: map-get($pl_headline50, "line-height");
    padding: 12px 40px 12px 0;
    position: relative;
    user-select: none;
    z-index: 1;

    .pl_icon:not(.pl_accordion__header-icon):last-of-type {
      display: block;
      fill: $pl_color-black100;
      height: 24px;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 12px;
      transition: transform 0.2s ease-out;
      width: 24px;
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
      }
    }

    + .pl_accordion__content {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transform: translateY(-30%);
      transition: opacity 250ms cubic-bezier(0.33, 1, 0.68, 1),
        transform 250ms cubic-bezier(0.33, 1, 0.68, 1);
    }

    &.pl_accordion__header--open {
      .pl_icon:not(.pl_accordion__header-icon):last-of-type {
        transform: rotate(-180deg);
      }

      + .pl_accordion__content {
        height: auto;
        margin-bottom: 12px;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
