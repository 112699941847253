%tableItemBase {
  border-top: 1px solid $pl_color-grey100;
  padding-top: 12px;
  padding-bottom: 12px;
}

.pl_table-view {
  padding: 0 16px;
  flex-direction: column;

  &--full-bleed {
    padding: 0;
  }

  > .pl_table-view__item {
    @extend %tableItemBase;

    &--fully-clickable {
      cursor: pointer;
      padding: 0;
    }

    &--no-divider {
      border-top: none;
    }
  }

  > .pl_table-view--headline, /*     */
  > .pl_table-view__headline {
    height: 48px;
    padding-top: 24px;
    padding-bottom: 8px;
    color: $pl_color-grey400;
  }

  /*     */
  > .pl_table-view--item {
    @extend %tableItemBase;

    &-no-divider {
      @extend .pl_table-view--item;
      border-top: none;
    }

    &-fully-clickable {
      @extend .pl_table-view--item;
      cursor: pointer;
      padding: 0;
    }
  }
}
