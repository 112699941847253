@use "~@otto-ec/global-frontend-settings/gfs" as gfs;

%pl_media-object {
  align-items: center;
  background: $pl_color-white100;
  border-radius: $pl_border-radius100;
  color: $pl_color-black100;
  display: flex;
  cursor: pointer;
  overflow: hidden;

  .pl_media-object--image, /*     */
  .pl_media-object--image--red, /*                         */
  .pl_media-object__image {
    height: 100%;
    overflow: hidden;
    position: relative;

    > img {
      height: 100%;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      width: auto;
    }

    > svg {
      box-sizing: content-box;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      height: 48px;
      width: 48px;
    }

    &.pl_media-object--image-border50, /*     */
    &--border50 {
      border: transparent solid $pl_padding50;
      > img {
        max-width: 100%;
        object-fit: contain;
      }
    }
  }

  .pl_media-object--content, /*     */
  .pl_media-object__content {
    flex: 1;

    .pl_media-object--headline, /*     */
    .pl_media-object--subline , /*     */
    .pl_media-object__headline,
    .pl_media-object__subline {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      /*                                                                  */
      width: calc(100vw - 198px);
      @supports (-webkit-line-clamp: 1) {
        white-space: unset;
        width: unset;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  @include gfs.from-l() {
    .pl_media-object--image--red {
      /*     */
      > svg {
        fill: $pl_color-red100;
      }
    }
  }
}

/*             */

.pl_media-object, /*     */
.pl_media-object100--red, /*     */
.pl_media-object100 {
  @extend %pl_media-object;

  height: 72px;

  .pl_media-object--content, /*     */
  .pl_media-object__content {
    box-sizing: border-box;
    padding: 10px 16px;

    .pl_media-object--headline, /*     */
    .pl_media-object__headline {
      @extend .pl_headline200;
      font-size: map-get($pl_headline200, "font-size");
      line-height: map-get($pl_headline200, "line-height");
    }

    .pl_media-object--subline, /*     */
    .pl_media-object__subline {
      @extend .pl_copy100;
      line-height: map-get($pl_copy100, "line-height");
    }

    @supports (-webkit-line-clamp: 1) {
      .pl_media-object--headline, /*     */
      .pl_media-object--subline, /*     */
      .pl_media-object__headline,
      .pl_media-object__subline {
        -webkit-line-clamp: 1;
      }
    }
  }

  .pl_media-object--image, /*     */
  .pl_media-object--image--red, /*     */
  .pl_media-object__image {
    height: 72px;
    width: 72px;

    &.pl_media-object--icon /*     */
    {
      > svg.pl_icon {
        left: 16px;
      }

      + .pl_media-object--content, /*     */
      + .pl_media-object__content {
        padding-left: unset;
      }
    }
  }

  .pl_media-object__icon {
    @extend .pl_media-object__image;

    > svg.pl_icon {
      width: 48px;
      height: 48px;
      left: 16px;
    }

    + .pl_media-object__content {
      padding-left: unset;
    }
  }

  @mixin mobile-view {
    height: 104px;
    @supports (-webkit-line-clamp: 1) {
      .pl_media-object--content, /*     */
      .pl_media-object__content {
        .pl_media-object--headline, /*     */
        .pl_media-object__headline {
          font-size: map-get($pl_headline100, "font-size");
          line-height: map-get($pl_headline100, "line-height");
          -webkit-line-clamp: 2;
        }

        .pl_media-object--subline, /*     */
        .pl_media-object__subline {
          -webkit-line-clamp: 2;
        }

        &--long-headline {
          .pl_media-object__headline {
            -webkit-line-clamp: 3;
          }

          .pl_media-object__subline {
            -webkit-line-clamp: 1;
          }
        }
      }
    }
    .pl_media-object--image, /*     */
    .pl_media-object--image--red, /*     */
    .pl_media-object__image {
      height: 104px;
      width: 104px;

      &.pl_media-object--icon {
        /*     */
        height: 96px;
        width: 96px;

        > svg.pl_icon {
          left: 16px;
        }

        + .pl_media-object--content, /*     */
        + .pl_media-object__content {
          padding-left: unset;
        }
      }

      > svg {
        left: 28px;
        width: 64px;
        height: 64px;
      }
    }

    .pl_media-object__icon {
      height: 96px;
      width: 96px;

      > svg.pl_icon {
        width: 64px;
        height: 64px;
        left: 16px;
      }

      + .pl_media-object__content {
        padding-left: unset;
      }
    }
  }

  &.pl_media-object100--non-responsive, /*     */
  &--non-responsive {
    @include mobile-view;
  }

  @include gfs.from-s(gfs.$breakpoint-l) {
    @include mobile-view;
  }
}

/*            */

.pl_media-object75--red, /*     */
.pl_media-object75 {
  @extend %pl_media-object;

  height: 80px;

  .pl_media-object--content, /*     */
  .pl_media-object__content {
    padding: 12px;

    .pl_media-object--headline, /*     */
    .pl_media-object__headline {
      @extend .pl_headline50;
      font-size: map-get($pl_headline50, "font-size");
      line-height: map-get($pl_copy100, "line-height");
    }

    .pl_media-object--subline, /*     */
    .pl_media-object__subline {
      @extend .pl_copy100;
      line-height: map-get($pl_copy100, "line-height");
    }

    @supports (-webkit-line-clamp: 1) {
      .pl_media-object--headline, /*     */
      .pl_media-object__headline {
        -webkit-line-clamp: 2;
      }

      .pl_media-object--subline, /*     */
      .pl_media-object__subline {
        -webkit-line-clamp: 1;
      }

      &.pl_media-object-subline-long, /*     */
      &--long-subline {
        .pl_media-object--headline, /*     */
        .pl_media-object__headline {
          -webkit-line-clamp: 1;
        }

        .pl_media-object--subline, /*     */
        .pl_media-object__subline {
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .pl_media-object--image, /*     */
  .pl_media-object--image--red, /*     */
  .pl_media-object__image {
    height: 80px;
    width: 80px;

    &.pl_media-object--icon, /*     */
    &--icon {
      > svg.pl_icon {
        left: 16px;
      }

      + .pl_media-object--content, /*     */
      + .pl_media-object__content {
        padding-left: unset;
      }
    }

    > svg {
      left: 22px;
      width: 48px;
      height: 48px;
    }
  }

  .pl_media-object__icon {
    @extend .pl_media-object__image;

    > svg.pl_icon {
      left: 16px;
      width: 48px;
      height: 48px;
    }

    + .pl_media-object__content {
      padding-left: unset;
    }
  }
}

/*            */

.pl_media-object50--red, /*     */
    .pl_media-object50 {
  @extend %pl_media-object;

  height: 64px;

  .pl_media-object--content, /*     */
  .pl_media-object__content {
    padding: 12px;

    .pl_media-object--headline, /*     */
    .pl_media-object__headline {
      @extend .pl_headline50;
      font-size: map-get($pl_headline50, "font-size");
      line-height: 20px;
    }

    .pl_media-object--subline, /*     */
    .pl_media-object__subline {
      @extend .pl_copy75;
      font-size: map-get($pl_copy75, "font-size");
      line-height: map-get($pl_copy75, "line-height");
    }

    @supports (-webkit-line-clamp: 1) {
      .pl_media-object--headline, /*     */
      .pl_media-object__headline {
        -webkit-line-clamp: 2;
      }

      .pl_media-object--subline, /*     */
      .pl_media-object__subline {
        -webkit-line-clamp: 1;
      }

      &.pl_media-object-subline-long /*     */
      &--long-subline {
        .pl_media-object--headline, /*     */
        .pl_media-object__headline {
          -webkit-line-clamp: 1;
        }
        .pl_media-object--subline, /*     */
        .pl_media-object__subline {
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .pl_media-object--image, /*     */
  .pl_media-object--image--red,  /*     */
  .pl_media-object__image {
    height: 64px;
    width: 64px;

    &.pl_media-object--icon, /*     */
    &--icon {
      > svg.pl_icon {
        left: 12px;
      }

      + .pl_media-object--content, /*     */
      + .pl_media-object__content {
        padding-left: unset;
      }
    }

    > svg {
      left: 18px;
      width: 40px;
      height: 40px;
    }
  }

  .pl_media-object__icon {
    @extend .pl_media-object__image;
    > svg.pl_icon {
      left: 12px;
      width: 40px;
      height: 40px;
    }

    + .pl_media-object__content {
      padding-left: unset;
    }
  }
}

/*            */

.pl_media-object25--red, /*     */
.pl_media-object25 {
  @extend %pl_media-object;

  height: 48px;

  .pl_media-object--content, /*     */
  .pl_media-object__content {
    padding: 8px;

    .pl_media-object--headline, /*     */
    .pl_media-object__headline {
      @extend .pl_copy100;
      font-size: map-get($pl_copy100, "font-size");
      line-height: 20px;
      font-weight: normal;
    }

    .pl_media-object--subline, /*     */
    .pl_media-object__subline {
      @extend .pl_copy75;
      font-size: map-get($pl_copy75, "font-size");
      line-height: map-get($pl_copy75, "line-height");
    }

    @supports (-webkit-line-clamp: 1) {
      .pl_media-object--headline, /*     */
      .pl_media-object--subline, /*     */
      .pl_media-object__headline,
      .pl_media-object__subline {
        -webkit-line-clamp: 1;
      }

      &.pl_media-object-headline-only /*     */
      &--headline-only {
        .pl_media-object--headline, /*     */
        .pl_media-object__headline {
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .pl_media-object--image, /*     */
  .pl_media-object--image--red, /*     */
  .pl_media-object__image {
    height: 48px;
    width: 48px;

    &.pl_media-object--icon, /*     */
    &--icon {
      > svg.pl_icon {
        left: 8px;
      }

      + .pl_media-object--content, /*     */
      + .pl_media-object__content {
        padding-left: unset;
      }
    }

    > svg {
      left: 12px;
      width: 32px;
      height: 32px;
    }
  }

  .pl_media-object__icon {
    @extend .pl_media-object__image;
    > svg.pl_icon {
      left: 8px;
      width: 32px;
      height: 32px;
    }

    + .pl_media-object__content {
      padding-left: unset;
    }
  }
}

/*             */

.pl_media-object, /*     */
.pl_media-object100,
.pl_media-object75,
.pl_media-object50,
.pl_media-object25 {
  &--red {
    @extend %pl_media-object;

    background: $pl_color-red100;
    color: $pl_color-white100;

    .pl_media-object--image, /*     */
    .pl_media-object__icon {
      > svg {
        fill: $pl_color-white100;
      }
    }
  }
}

.pl_media-object_wrapper, /*     */
.pl_media-object-wrapper {
  display: flex;
  flex-wrap: wrap;

  .pl_media-object--red, /*     */
  .pl_media-object100--red,/*     */
  .pl_media-object75--red, /*     */
  .pl_media-object50--red, /*     */
  .pl_media-object25--red, /*     */
  .pl_media-object,
  .pl_media-object100,
  .pl_media-object75,
  .pl_media-object50,
  .pl_media-object25 {
    flex: 1;

    margin-left: $pl_margin50;
    margin-right: $pl_margin50;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@include gfs.from-l() {
  .pl_media-object_wrapper, /*     */
  .pl_media-object-wrapper {
    .pl_media-object:only-child,
    .pl_media-object100:only-child,
    .pl_media-object75:only-child,
    .pl_media-object50:only-child,
    .pl_media-object25:only-child,
    .pl_media-object--red:only-child, /*     */
    .pl_media-object100--red:only-child, /*     */
    .pl_media-object75--red:only-child, /*     */
    .pl_media-object50--red:only-child, /*     */
    .pl_media-object25--red:only-child /*     */
    {
      .pl_media-object--content, /*     */
      .pl_media-object__content {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex-wrap: wrap;

        .pl_media-object--headline, /*     */
        .pl_media-object__headline {
          margin-right: $pl_margin50;
        }
      }
    }
  }
}
