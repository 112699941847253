@use "../../common/scss/focus";

@mixin disabled-button-state {
  background-color: $pl_color-grey100;
  color: $pl_color-grey400;
  cursor: default;
  font-weight: normal;

  > svg {
    fill: $pl_color-grey400;
  }
}

%pl_basisSettingsButtonStyling {
  align-items: center;
  border-radius: $pl_border-radius50;
  border: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  outline: 0 none;
  position: relative;
  text-align: center;
  text-decoration: none; /*                  */
  user-select: none;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }

    &[disabled]:hover {
      @include disabled-button-state;
    }
  }

  @include focus.styles;

  &[disabled]:active,
  &[disabled] {
    @include disabled-button-state;
  }

  &[disabled] {
    background-color: $pl_color-grey100;
    color: $pl_color-grey400;
    cursor: default;
    font-weight: normal;

    > svg {
      fill: $pl_color-grey400;
    }
  }

  > i {
    display: inline-block;
    font-family: $iconsFontFamily !important;
    font-style: normal;
    vertical-align: bottom;
    word-spacing: 6px;
  }

  > svg {
    display: inline-block;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

%pl_settingsButton100,
.pl_button100--primary,
.pl_button100--secondary {
  @extend %pl_basisSettingsButtonStyling;

  height: 48px;
  padding: $pl_padding75 $pl_padding100;
  width: 100%;

  > span:not(:only-child) {
    /*                                                                          */
    padding-left: $pl_padding150;
    padding-right: $pl_padding150;
  }

  > i {
    font-size: 24px;
  }

  > svg {
    height: 24px;
    width: 24px;
  }

  > i,
  > svg {
    left: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  > span + i,
  > span + svg {
    left: auto;
    right: 12px;
  }
}

%pl_settingsButton50 {
  @extend %pl_basisSettingsButtonStyling;

  height: 32px;
  padding: $pl_padding50;
  display: inline-flex;
  max-width: 100%;

  > span {
    flex: 1 0 auto;
    margin: 0 auto;
    padding: $pl_padding50;
    width: calc(100% - 20px);
  }

  > i {
    font-size: 16px;
  }

  > i,
  > svg {
    margin-left: 0;
    margin-right: auto;
    flex: 0 0 12px;
  }

  > span + i,
  > span + svg {
    margin-left: auto;
    margin-right: 0;
  }
}

.pl_button100--primary {
  @extend %pl_settingsButton100;

  background-color: $pl_color-red100;
  color: $pl_color-white100;
  font-size: map-get($pl_headline100, "font-size");
  font-weight: map-get($pl_headline100, "font-weight");

  @media (hover: hover) {
    &:hover {
      background-color: darken($pl_color-red100, 10);
    }
  }

  &:active:not([disabled]) {
    background-color: darken($pl_color-red100, 20);
  }

  > svg {
    fill: $pl_color-white100;

    path {
      fill: inherit;
    }
  }
}

.pl_button100--secondary {
  @extend %pl_settingsButton100;

  background-color: $pl_color-grey100;
  color: $pl_color-black100;
  font-weight: normal;
  font-size: map-get($pl_copy125, "font-size");

  @media (hover: hover) {
    &:hover {
      background-color: darken($pl_color-grey100, 10);
    }
  }

  &:active:not([disabled]) {
    background-color: darken($pl_color-grey100, 20);
  }

  > svg {
    fill: $pl_color-black100;

    path {
      fill: inherit;
    }
  }
}

.pl_button100--text {
  @extend .pl_button100--secondary;

  background: none;
  color: $pl_color-blue100;

  @media (hover: hover) {
    &:hover {
      background-color: $pl_color-grey100;
    }
  }

  &:active {
    background-color: $pl_color-grey100;
  }

  > svg {
    fill: $pl_color-blue100;

    path {
      fill: inherit;
    }
  }
}

.pl_button50--primary {
  @extend %pl_settingsButton50;

  background-color: $pl_color-red100;
  color: $pl_color-white100;
  font-size: map-get($pl_headline25, "font-size");
  font-weight: map-get($pl_headline25, "font-weight");

  @media (hover: hover) {
    &:hover {
      background-color: darken($pl_color-red100, 10);
    }
  }

  &:active:not([disabled]) {
    background-color: darken($pl_color-red100, 20);
  }

  > svg {
    fill: $pl_color-white100;

    path {
      fill: inherit;
    }
  }
}

.pl_button50--secondary {
  @extend %pl_settingsButton50;

  background-color: $pl_color-grey100;
  color: $pl_color-black100;
  font-size: map-get($pl_copy75, "font-size");
  font-weight: normal;

  @media (hover: hover) {
    &:hover {
      background-color: darken($pl_color-grey100, 10);
    }
  }

  &:active:not([disabled]) {
    background-color: darken($pl_color-grey100, 20);
  }

  > svg {
    fill: $pl_color-black100;

    path {
      fill: inherit;
    }
  }
}

.pl_button50--text {
  @extend .pl_button50--secondary;

  background: none;
  color: $pl_color-blue100;

  /*                                                      */
  @media (hover: hover) {
    &:hover {
      background-color: $pl_color-grey100;
    }
  }

  &:active {
    background-color: $pl_color-grey100;
  }

  > svg {
    fill: $pl_color-blue100;

    path {
      fill: inherit;
    }
  }
}

.pl_button100--fit-content {
  width: auto;

  padding: $pl_padding75 0;

  &.pl_button100--primary,
  &.pl_button100--text,
  &.pl_button100--secondary {
    span:only-child {
      padding: $pl_padding75 $pl_padding100;
    }

    svg:first-child,
    svg:last-child {
      margin: 0 $pl_margin75;
    }

    span:not(:only-child) {
      padding: 0;

      &:first-child:not(svg) {
        padding-left: $pl_padding150;
        padding-right: 0;
      }
      &:last-child:not(svg) {
        padding-left: 0;
        padding-right: $pl_padding150;
      }
    }
  }

  > i,
  > svg {
    position: relative;
    left: auto;
    margin-left: -$pl_margin25;
    margin-right: $pl_margin75;
    transform: none;
    top: 0;
  }

  > i {
    font-size: 24px;
  }

  > svg {
    height: 24px;
    width: 24px;
  }

  > span + i,
  > span + svg {
    right: auto;
    margin-left: $pl_margin75;
    margin-right: -$pl_margin25;
  }
}
