$pl_badge-border-width: 1px;

%pl_badge {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-family: $sansFontFamily;
  font-size: map-get($pl_headline10, "font-size");
  font-weight: map-get($pl_headline10, "font-weight");
  line-height: map-get($pl_headline10, "line-height");

  padding-left: 5px;
  padding-right: 5px;

  height: 18px;
  min-width: 18px;

  border: $pl_badge-border-width solid $pl_color-white100;
  border-radius: calc($pl_border-radius50 + $pl_badge-border-width);
}

.pl_badge {
  &--red {
    @extend %pl_badge;
    background: $pl_color-red100;
    color: $pl_color-white100;
  }
  &--grey {
    @extend %pl_badge;
    background: $pl_color-grey400;
    color: $pl_color-white100;
  }
  &--green {
    @extend %pl_badge;
    padding: 0px;
    background: $pl_color-success;
    color: $pl_color-white100;

    > svg {
      fill: $pl_color-white100;
      vertical-align: text-top;
    }
  }
}
