@use "~@otto-ec/global-frontend-settings/gfs";

/*                                                           */
%p_basisSettingsBoxSizingOnly {
  box-sizing: border-box;
}

%pl_headlineBase {
  font-family: $sansFontFamily;
}

@mixin pl_headlineFontStyles($fontMap) {
  font-size: map-get($fontMap, "font-size");
  font-weight: map-get($fontMap, "font-weight");
  line-height: map-get($fontMap, "line-height");
}

$sizes: "10" $pl_headline10, "25" $pl_headline25, "50" $pl_headline50, "100" $pl_headline100,
  "200" $pl_headline200, "300" $pl_headline300;
$breakpoints: "md" "m", "lg" "l", "xl" "xl";

@each $sizeName, $fontMap in $sizes {
  .pl_headline#{$sizeName} {
    @extend %p_basisSettingsBoxSizingOnly;
    @extend %pl_headlineBase;
    @include pl_headlineFontStyles($fontMap);
  }
}

@each $breakpoint-name, $breakpoint-identifier in $breakpoints {
  @include gfs.breakpoint($breakpoint-identifier) {
    @each $size, $fontMap in $sizes {
      .pl_headline#{$size}--#{$breakpoint-name} {
        @include pl_headlineFontStyles($fontMap);
      }
    }
  }
}
