@use "~@otto-ec/global-frontend-settings/gfs";

%pl_tagBase {
  border-radius: $pl_border-radius10;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-weight: bold;
  color: $pl_color-white100;
}

$sizes: "25" 16px $pl_headline10 $pl_padding25, "50" 20px $pl_headline25 $pl_padding25,
  "100" 24px $pl_headline50 $pl_padding50;

$colors: "sustainable" $pl_color-sustainable, "red" $pl_color-red100, "black" $pl_color-black100,
  "grey" $pl_color-grey400, "warm-red" $pl_color-warm-red, "deep-navy" $pl_color-deep-navy;

$breakpoints: "md" "m", "lg" "l", "xl" "xl";

@each $size, $height, $fontVar, $padding-x in $sizes {
  @each $colorName, $background in $colors {
    .pl_tag#{$size}--#{$colorName} {
      @extend %pl_tagBase;
      padding: 0 $padding-x;
      height: $height;
      line-height: map-get($fontVar, "line-height");
      font-size: map-get($fontVar, "font-size");
      font-weight: map-get($fontVar, "font-weight");
      background: $background;
    }
  }
}

@each $breakpoint-name, $breakpoint-identifier in $breakpoints {
  @include gfs.breakpoint($breakpoint-identifier) {
    @each $size, $height, $fontVar, $padding-x in $sizes {
      .pl_tag#{$size}--#{$breakpoint-name} {
        padding: 0 $padding-x;
        height: $height;
        line-height: map-get($fontVar, "line-height");
        font-size: map-get($fontVar, "font-size");
        font-weight: map-get($fontVar, "font-weight");
      }
    }
  }
}
