$patternName: p_selectBtn;

.#{$patternName}50,
.#{$patternName}100 {
  outline: none;
  background: $white;
  padding: 2px;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus,
  &::-moz-focus-inner {
    outline: none;
    border: 0;
    -moz-outline-style: none;
  }

  &:active i,
  &:focus i {
    position: relative;

    top: 0;
    left: 0;
  }

  i {
    background-position: center center;
    background-repeat: no-repeat;
    /*                                    */
    /*                   */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               */
    display: block;
    width: 100%;

    height: 100%;
  }

  @include set-hover-and-active-style {
    i {
      /*                 */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         */
    }
  }

  &--selected i {
    /*               */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http:/*                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        */
  }
  &--animated i {
    animation-name: #{$patternName};
    animation-duration: 0.2s;
    transition: transform 500ms ease-in-out;
  }
  &--animated-reverse i {
    animation-name: #{$patternName}-reverse;
    animation-duration: 0.2s;
    transition: transform 500ms ease-in-out;
  }
  /*                  */
}

.#{$patternName}100 {
  width: 40px;
  height: 40px;
  padding: 6px;
}

@keyframes #{$patternName} {
  0% {
    opacity: 0.1;
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
  }
}

@keyframes #{$patternName}-reverse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
