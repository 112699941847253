.p_radio,
.pl_radio {
  /*                                                                        */
  /*                                                                                                                                 */
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  &:after {
    clear: both;
    content: "";
    display: table;
  }

  &__button {
    -webkit-appearance: none;
    border-radius: 50% 50%;
    border: 1px solid $pl_color-grey200;
    display: inline-block;
    flex-shrink: 0;
    height: 24px;
    margin: 0;
    outline: none;
    width: 24px;
    cursor: pointer;
    &:checked {
      border-color: $pl_color-grey200 !important;
    }

    &:checked:after {
      background: $pl_color-black100;
      border-radius: 50%;
      content: "\00a0";
      display: block;
      height: 10px;
      left: 6px;
      position: relative;
      top: 6px;
      width: 10px;

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__label {
    color: $pl_color-black100;
    display: inline-block;
    font-size: map-get($pl_copy125, "font-size");
    line-height: map-get($pl_copy125, "line-height");
    max-width: calc(100% - 40px);
    margin-left: 16px;
    padding: 0;
    cursor: pointer;
    &--full-width {
      @extend .pl_radio__label;
      display: flex;
      flex: 1 0 auto;
      justify-content: space-between;
      max-width: none;
    }
    + .pl_radio__note {
      margin-top: 5px;
    }
  }

  &__note {
    clear: both;
    display: block;
    flex-basis: 100%;
    font-size: map-get($pl_copy75, "font-size");
    line-height: map-get($pl_copy75, "line-height");
  }

  &--invalid {
    .p_radio__button,
    .pl_radio__button {
      border-color: $pl_color-red100;
    }
    .p_radio__note,
    .pl_radio__note {
      color: $pl_color-red100;
    }
  }
}

.pl_radio__button:disabled {
  background-color: $pl_color-grey25;
  border-color: $pl_color-grey100;

  &:checked:after {
    background: $pl_color-grey200;
  }

  & + .pl_radio__label,
  & + .pl_radio__note,
  & + .pl_radio__label + .pl_radio__note {
    color: $pl_color-grey400;
  }
}
