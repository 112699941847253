/*            */
$fontLabel: (
  font-size: 10px,
  line-height: 15px,
);

%p_label {
  box-sizing: border-box;
  color: $white;
  display: inline-block;
  font-weight: bold;
  letter-spacing: 0.06rem;
  text-align: center;
  vertical-align: bottom;
}

.p_label100 {
  @extend %p_label;
  @include font-size($fontLabel, true);

  background: $red100;
  border-radius: 0.19rem;
  padding: 0 0.3rem 0;

  &--icon {
    font-family: $iconFontFamily;
    font-style: normal;
    padding: 0 0.16rem 0;
  }
}
