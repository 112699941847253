@keyframes plSnackbarFadeInDesktop {
  from {
    opacity: 0;
    transform: translate(-50%, 100%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes plSnackbarFadeOutDesktop {
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  to {
    opacity: 0;
    transform: translate(-50%, 100%);
  }
}

@keyframes plSnackbarFadeInMobile {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plSnackbarFadeOutMobile {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

.pl_snackbar {
  align-items: center;
  animation-duration: 100ms;
  animation-timing-function: linear;
  background-color: $pl_color_black100;
  border-radius: $pl_border-radius10;
  bottom: 16px;
  color: $pl_color_white100;
  display: flex;
  opacity: 1;
  position: fixed;
  transform: translateY(100%);
  z-index: 9999;

  &.pl_snackbar--in-sheet {
    left: 8px;
    max-width: calc(100% - 16px);
    position: absolute;
    transform: unset;
    width: 100%;
  }

  > .pl_snackbar__content {
    flex: 1 1 auto;
    padding: $pl_padding75 $pl_padding75 $pl_padding75 $pl_padding100;
    overflow: hidden;
  }

  > .pl_snackbar__action-link {
    color: $pl_color_white100;
    flex: 0 1 fit-content;
    padding: $pl_padding75;
  }

  > svg {
    box-sizing: content-box;
    cursor: pointer;
    fill: $pl_color_white100;
    flex: 0 0 auto;
    height: 24px;
    padding: $pl_padding75;
    width: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .pl_snackbar {
    &:not(&.pl_snackbar--in-sheet) {
      transform: translateX(-50%);
    }

    &.pl_snackbar--in-sheet {
      &.pl_snackbar--fade-in {
        animation-name: plSnackbarFadeInMobile;
      }
    }

    &.pl_snackbar--in-sheet {
      &.pl_snackbar--fade-out {
        animation-name: plSnackbarFadeOutMobile;
      }
    }

    &.pl_snackbar--fade-in {
      animation-name: plSnackbarFadeInDesktop;
    }

    &.pl_snackbar--fade-out {
      animation-name: plSnackbarFadeOutDesktop;
    }
  }
}

@media only screen and (max-width: 767px) {
  .pl_snackbar {
    animation-name: plSnackbarFadeInMobile;
    left: 8px;
    min-width: 0;
    transform: unset;
    width: calc(100vw - 16px);

    &.pl_snackbar--fade-out {
      animation-name: plSnackbarFadeOutMobile;
    }
  }
}

@media only screen and (max-width: 479px) {
  :not(.pl_sheet__menu-bar-hidden) > .pl_sheet__menu-bar + .pl_snackbar--in-sheet {
    bottom: calc(96px + 16px); /*                                          */
  }
}
