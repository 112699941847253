%tooltipBase {
  background-color: $pl_color_black100;
  border-radius: $pl_border-radius10;
  color: $pl_color_white100;
  width: fit-content;
  padding: $pl_padding75 48px $pl_padding75 $pl_padding75;
  position: absolute;
  text-align: left;
  z-index: 9999;

  > svg {
    box-sizing: content-box;
    cursor: pointer;
    fill: $pl_color_white100;
    height: 24px;
    padding: $pl_padding75;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
  }

  > .pl_tooltip__anchor {
    &-bottom {
      @extend %triangleBase;
      bottom: -8px;
      transform: rotate(180deg);
    }

    &-top {
      @extend %triangleBase;
      top: -8px;
    }
  }
}

%triangleBase {
  border-bottom: 8px solid $pl_color_black100;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  height: 0;
  position: absolute;
  width: 0;
}

.pl_tooltip {
  &--top {
    @extend %tooltipBase;
    transform: translateY(-100%);
  }

  &--bottom {
    @extend %tooltipBase;
    transform: translateY(0);
  }

  &--hidden {
    opacity: 0;
  }
}

@media only screen and (max-width: 447px) {
  .pl_tooltip--top,
  .pl_tooltip--bottom {
    left: 8px;
    width: calc(100% - 16px);
  }
}

@media only screen and (min-width: 448px) {
  .pl_tooltip--top,
  .pl_tooltip--bottom {
    max-width: 240px;
  }
}
